import React, { useEffect, useState } from 'react'
import TypeButton from '../elements/TypeButton'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { get } from '../../api/customAxios'
import { TimeDate } from '../../types/TimeDate'
import styled from '@emotion/styled'
import { AxiosError } from 'axios'
import { ErrorMessage } from '../../types/Error'

const RecruitSuccess = () => {
    const navigate = useNavigate()
    const [result, setResult] = useState<TimeDate>({
        apply_start: '',
        apply_end: '',
        first_start: '',
        first_end: '',
        second_start: '',
        second_end: '',
        announce_date: '',
    })
    const handleResultGetApi = async () => {
        try {
            const res = await get<TimeDate>(
                `${process.env.REACT_APP_PREFIX}/applies/announces`,
            )
            setResult(res.data)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }

    const timeFormat = (date: string) => {
        return dayjs(date).format('YYYY년 MM월 DD일')
    }
    useEffect(() => {
        handleResultGetApi()
    }, [])

    return (
        <Layout>
            <div className="contents">
                <h2>지원해 주셔서 감사합니다</h2>
            </div>
            <div className="result_message">
                최종합격 여부는 이메일로 전달 되오니 이메일을 확인해주시기
                바랍니다.
                <br /> 서류심사 과정에서 다시 제출이 필요한 문서는 이메일로
                요청을 드릴 예정입니다.
                <br />
                이메일을 상시로 확인해 주시면 감사하겠습니다.
            </div>
            <div className="schedule">
                <div className="title">
                    <h3>진행 일정 안내</h3>
                </div>
                <div className="result_date">
                    <p>
                        서류심사 기간 :{' '}
                        {timeFormat(result.apply_start) +
                            ' ~ ' +
                            timeFormat(result.apply_end)}
                    </p>
                    <p>
                        1차 심사위원 평가 기간 :{' '}
                        {timeFormat(result.first_start) +
                            ' ~ ' +
                            timeFormat(result.first_end)}
                    </p>
                    <p>
                        2차 심사위원 평가 기간 :{' '}
                        {timeFormat(result.second_start) +
                            ' ~ ' +
                            timeFormat(result.second_end)}
                    </p>
                    <p>최종 합격 발표 : {timeFormat(result.announce_date)}</p>
                </div>
            </div>
            <TypeButton
                icon={false}
                sort={false}
                onClick={() => navigate('/')}
                type={'confirm'}
            >
                목록으로
            </TypeButton>
        </Layout>
    )
}

export default RecruitSuccess

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    .contents {
        margin-bottom: 20px;
        h2 {
            font-size: 24px;
            font-weight: 700;
            line-height: 29px;
        }
    }
    .result_message {
        font-weight: 600;
        line-height: 23px;
        text-align: center;
        margin-bottom: 70px;
    }
    .schedule {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        .title {
            margin-bottom: 20px;
        }
        h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
        }
        .result_date {
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 23px;
        }
    }
`
