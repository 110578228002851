import styled from '@emotion/styled'
import React from 'react'
import { ErrorCheck } from '../../ pages/OperInfo'
import { OperationInfo } from '../../types/Operation'
import colors from '../../utils/colors'
import Input from '../elements/Input'
import SingleCheckIcon from '../icon/SingleCheckIcon'

interface Props {
    operationInfo: OperationInfo
    errorCheck: ErrorCheck
    change: Function
    setter: Function
}
const InfoForm: React.FC<Props> = ({
    operationInfo,
    change,
    setter,
    errorCheck,
}) => {
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setter((prev: OperationInfo) => {
            return { ...prev, [name]: checked }
        })
    }

    return (
        <Layout>
            <FormTable>
                <tbody>
                    <tr>
                        <th className="required">주민등록번호</th>
                        <td colSpan={3}>
                            <Input
                                onChange={(e) => change(e, 'resident_first')}
                                type={'text'}
                                name={'resident_first'}
                                height={40}
                                value={operationInfo.resident_first}
                                placeholder="생년월일"
                                max={6}
                                disabled={!operationInfo.agreement}
                            />
                            <span className="dash">{'-'}</span>
                            <Input
                                onChange={(e) => change(e, 'resident_last')}
                                type={'password'}
                                name={'resident_last'}
                                height={40}
                                value={operationInfo.resident_last}
                                placeholder="주민번호 뒷자리"
                                max={7}
                                disabled={!operationInfo.agreement}
                            />
                            {!errorCheck.resident_valid && (
                                <Wrong>{errorCheck.resident_message}</Wrong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th className="required">은행명</th>
                        <td width={200}>
                            <Input
                                onChange={(e) => change(e, 'bank_name')}
                                type={'text'}
                                name={'bank_name'}
                                width={130}
                                height={40}
                                value={operationInfo.bank_name}
                                placeholder="은행명"
                                max={20}
                                disabled={!operationInfo.agreement}
                            />
                            {!errorCheck.bank_name_valid && (
                                <Wrong>{errorCheck.bank_name_message}</Wrong>
                            )}
                        </td>
                        <th className="required">예금주</th>
                        <td>
                            <Input
                                onChange={(e) => change(e, 'holder_name')}
                                type={'text'}
                                name={'holder_name'}
                                width={130}
                                height={40}
                                value={operationInfo.holder_name}
                                placeholder="계좌 소유자 이름"
                                max={40}
                                disabled={!operationInfo.agreement}
                            />
                            {!errorCheck.holder_name_valid && (
                                <Wrong>{errorCheck.holder_name_message}</Wrong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th className="required">계좌번호</th>
                        <td colSpan={3}>
                            <Input
                                onChange={(e) => change(e, 'holder_name')}
                                type={'text'}
                                name={'account_number'}
                                width={400}
                                height={40}
                                value={operationInfo.account_number}
                                placeholder="-를 포함한 계좌번호를 적어주세요"
                                max={40}
                                disabled={!operationInfo.agreement}
                            />
                            {!errorCheck.account_number_valid && (
                                <Wrong>
                                    {errorCheck.account_number_message}
                                </Wrong>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{ paddingTop: 38 }}>
                            <Label>
                                <CheckBox>
                                    {operationInfo.medical_benefit_yn && (
                                        <SingleCheckIcon
                                            width={18}
                                            height={18}
                                        />
                                    )}
                                </CheckBox>
                                <Input2
                                    name={'medical_benefit_yn'}
                                    id={'medical_benefit_yn'}
                                    type={'checkbox'}
                                    // checked={operationInfo.medical_benefit_yn}
                                    onChange={handleCheck}
                                />
                                의료급여 수급자 여부
                            </Label>
                            의료급여 수급자인 경우만 체크해주세요
                        </td>
                    </tr>
                </tbody>
            </FormTable>
        </Layout>
    )
}

export default React.memo(InfoForm)

const Layout = styled.div`
    // overflow-x: scroll;
    // width: 300px;
    // height: 330px;
    padding: 16px 16px;

    box-sizing: border-box;

    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: ${colors.textBoldColor};
`
const Label = styled.label`
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #333333;
    margin-right: 12px;
`

const CheckBox = styled.div`
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid ${colors.mainColor};
    border-radius: 2px;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 12px;
`

const Input2 = styled.input`
    display: none;
`

const FormTable = styled.table`
    tr {
        th {
            height: 40px;
            padding-right: 16px;

            font-family: 'Pretendard';
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;

            letter-spacing: 0.003em;
            color: #333333;
            text-align: left;
            &:after {
                content: '*';
                margin-left: 2px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                color: #f94733;
            }
        }
        td {
            height: 40px;
            padding: 12px 0;
        }
    }
    .dash {
        margin: 0 12px;
    }
`

const Wrong = styled.div`
    position: relative;
    height: 0;
    top: 6px;
    left: 14px;
    // margin: 6px 0 0 14px;
    // padding: 6px 0 0 14px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.003em;

    color: #f94733;
`
