import styled from '@emotion/styled'
import React from 'react'

interface Props {
    src?: string
    height: number
    bgColor?: string
    children?: React.ReactNode
}
const Banner: React.FC<Props> = ({ src, height, children, bgColor }) => {
    return (
        <Layout height={height} bgColor={bgColor}>
            <div className="contents">
                <div
                    className="image_banner"
                    style={children ? { marginRight: 105 } : {}}
                >
                    <img src={src} alt="banner" />
                </div>
                <div>
                    {children && <div className="description">{children}</div>}
                </div>
            </div>
        </Layout>
    )
}

export default React.memo(Banner)

const Layout = styled.div<{ height: number; bgColor?: string }>`
    min-width: 1000px;
    width: 100%;
    height: ${({ height }) => height}px;
    background: ${({ bgColor }) => (bgColor ? bgColor : '#F2F7FF')};
    box-sizing: border-box;
    .contents {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1000px;
        margin: 0 auto;
    }
    .image_banner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;
    }

    .description {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 265px;
        height: 200px;

        color: #014680;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        letter-spacing: 0.02em;
        text-align: center;
    }
`
