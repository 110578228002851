import React from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import colors from '../../utils/colors'

interface Props {
    start: string
    end: string
    state?: string
    typeColor?: boolean
}
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Seoul')
const ReceiptPeriod: React.FC<Props> = ({ start, end, state, typeColor }) => {
    const date = (param: string) => {
        return dayjs(param)
    }

    const dayCheck = (param: number) => {
        if (param === 0) return '일'
        if (param === 1) return '월'
        if (param === 2) return '화'
        if (param === 3) return '수'
        if (param === 4) return '목'
        if (param === 5) return '금'
        if (param === 6) return '토'
    }
    const engSwitch = (param: string): string => {
        const str = dayjs(param).tz().format('hh:mm')
        const timeStr = dayjs(param)
            .format('hh:mm a')
            .split('')
            .splice(-2, 2)
            .join('')

        if (timeStr === 'am') return `오전 ${str}`
        if (timeStr === 'pm') return `오후 ${str}`
        return str
    }

    return (
        <Layout>
            <h2 className="receipt_header_text">접수기간</h2>
            <div>
                <div className="top_info">
                    {/* <h3 className="sub_title">남은 기간</h3> */}
                    <h3 className="sub_title">공고 현황</h3>
                    <div className="post_state_box">
                        <strong
                            className="post_state_text"
                            style={
                                !typeColor ? { color: colors.mainColor } : {}
                            }
                        >
                            {state}
                        </strong>
                    </div>
                    <div>
                        {dayjs(end).format('MM.DD') ===
                            dayjs().format('MM.DD') && (
                            <p className="post_end_time_text">
                                {engSwitch(end)} 마감
                            </p>
                        )}
                    </div>
                </div>
                <div className="date_body">
                    <StartWithEndText margin={'0 12px 0 0'}>
                        시작일
                    </StartWithEndText>
                    {start && (
                        <span className="date_text">
                            {date(start.toString()).format('YYYY.MM.DD')}
                            {''}({dayCheck(date(start).day())})
                        </span>
                    )}

                    <StartWithEndText margin={'0 13px 0 14px'}>
                        마감일
                    </StartWithEndText>
                    {end && (
                        <span className="date_text">
                            {date(end).format('YYYY.MM.DD')}
                            {''}({dayCheck(date(end).day())})
                        </span>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default ReceiptPeriod

const Layout = styled.div`
    display: flex;
    .receipt_header_text {
        font-size: 18;
        font-weight: 600;
        margin-right: 24px;
    }
    .top_info {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
    }
    .sub_title {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.textGray};
    }
    .post_state_box {
        margin: 0 14px 0 30px;
    }
    .post_state_text {
        font-size: 22px;
        font-weight: 600;
        color: red;
    }
    .post_end_time_text {
        font-size: 13px;
        color: ${colors.textGray};
    }
    .date_body {
        display: flex;
    }
    .date_text {
        font-size: 14px;
        font-weight: 600;
    }
`

const StartWithEndText = styled.p<{ margin?: string }>`
    font-size: 13px;
    color: ${colors.textGray};
    margin: ${({ margin }) => margin};
`
