import styled from '@emotion/styled'
import React, { Dispatch, SetStateAction } from 'react'
import colors from '../../utils/colors'

interface Props {
    width?: number
    height?: number
    subject: string
    setShowModal: Dispatch<SetStateAction<boolean>>
    children?: React.ReactNode
}
const SelfAppraisal: React.FC<Props> = ({
    width,
    height,
    subject,
    setShowModal,

    children,
}) => {
    return (
        <Contents width={width} height={height}>
            <div className="header">
                <div className="title">{subject}</div>
                <div onClick={() => setShowModal((prev) => !prev)}>
                    <CancelIcon />
                </div>
            </div>
            <div className="body">{children}</div>
        </Contents>
    )
}

export default SelfAppraisal

const Contents = styled.div<{ width?: number; height?: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    width: ${({ width }) => (width ? width : 620)}px;
    height: ${({ height }) => (height ? height : 468)}px;

    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
    box-shadow: 0px 0px 8px ${colors.borderGray};
    border-radius: 10px;
    box-sizing: border-box;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
    }
    .body {
        // overflow-x: scroll;
        // width: 560px;
        height: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${colors.inputTextColor};
    }
`

const CancelIcon = styled.i`
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/icon/Cancel.svg');
    cursor: pointer;
`
