import styled from '@emotion/styled'
import React from 'react'

interface Props {
    children: React.ReactNode
    title: string
    require?: boolean
    margin?: string
    labelWidth?: number
}
const InputGroup: React.FC<Props> = ({
    children,
    title,
    require,
    margin = '0 0 22px 0',
    labelWidth = 100,
}) => {
    return (
        <Layout margin={margin} labelWidth={labelWidth}>
            <div className="input_box">
                {title}
                {require && <p className="require">*</p>}
            </div>

            {children}
        </Layout>
    )
}

export default React.memo(InputGroup)

const Layout = styled.div<{ margin?: string; labelWidth?: number }>`
    display: flex;
    align-items: center;
    .input_box {
        display: flex;
        justify-content: flex-end;
        width: ${({ labelWidth }) => labelWidth}px;
        margin-right: 16px;
    }
    margin: ${({ margin }) => margin};
    &:last-of-type {
        margin-bottom: 0;
    }
`
