import React, { useCallback, useEffect, useState } from 'react'
import RequireText from '../elements/RequireText'
import { PatternFormat } from 'react-number-format'
import styled from '@emotion/styled'
import InputForm from './InputForm'
import InputWithTitle from './InputWithTitle'
import Input from '../elements/Input'
import TextArea from '../elements/TextArea'
import PrevWithNextArea from './PrevWithNextArea'
import { get, post } from '../../api/customAxios'
import { AxiosError } from 'axios'
import { dateCheck } from '../../utils/validation'
import { ErrorType } from '../../types/Error'

import colors from '../../utils/colors'

interface Career {
    id: number
    start_date: string
    end_date: string
    company: string
    work: string
    status: boolean
}

interface Certificate {
    id: number
    name: string
    certification_no: string
    publish: string
    certification_date: string
}
interface Answer {
    id: number
    question: string
    answer: string
}

interface Detilas {
    answers: Answer[]
    career: string
    certification: string
}

interface Props {
    onNext: () => void
    onPrev: () => void
}
const SelfIntroduction: React.FC<Props> = ({ onNext, onPrev }) => {
    const [success, setSuccess] = useState(false)
    const [validationErrorCheck, setValidationErrorCheck] = useState(false)
    // 정보 입력 잘못했을 경우의 에러메세지
    const [careerErrorTypeMessage, setCareerErrorTypeMessage] =
        useState<ErrorType>({
            id: 0,
            type: '',
            message: '',
            status: false,
        })

    const [certificateErrorTypeMessage, setCertificateErrorTypeMessage] =
        useState<ErrorType>({
            id: 0,
            type: '',
            message: '',
            status: false,
        })
    const [details, setDetails] = useState<Detilas>({
        career: '[]',
        certification: '[]',
        answers: [
            {
                id: 1,
                question: '',
                answer: '',
            },
        ],
    })
    const [careersModel, setCareersModel] = useState<Career[]>([
        {
            id: 1,
            start_date: '',
            end_date: '',
            company: '',
            work: '',
            status: false,
        },
    ])
    const [certificateModel, setCertificateModel] = useState<Certificate[]>([
        {
            id: 1,
            name: '',
            certification_no: '',
            publish: '',
            certification_date: '',
        },
    ])
    const handleAddCareer = () => {
        const ran = Math.random()
        const result = {
            id: 1 + ran,
            start_date: '',
            end_date: '',
            company: '',
            work: '',
            status: true,
        }
        if (careersModel.length < 20) {
            setCareersModel((prev: Career[]) => prev.concat(result))
        }
    }
    // 클릭 된 경력 사항 삭제
    const handleDeleteCareer = (id: number) => {
        setCareersModel((prev) => prev.filter((item) => item.id !== id))
    }

    const handleAddCertificate = () => {
        const ran = Math.random()
        const result = {
            id: 1 + ran,
            name: '',
            certification_no: '',
            publish: '',
            certification_date: '',
        }
        if (certificateModel.length < 20) {
            setCertificateModel((prev) => prev.concat(result))
        }
    }

    const handleDeleteCertificate = (id: number) => {
        setCertificateModel((prev) => prev.filter((item) => item.id !== id))
    }

    // 경력 사항 입력 폼
    const handleChangeCareer = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        const { name, value } = e.target

        setCareersModel((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        [name]: value,
                        status: false,
                    }
                }
                return item
            })
        )
    }

    // 같은 코드 중복 hooks로 뺄 것
    const handleChangeCertificate = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number
    ) => {
        const { name, value } = e.target

        setCertificateModel((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        [name]: value,
                        status: false,
                    }
                }
                return item
            })
        )
    }

    const handleAnswer = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        id: number
    ) => {
        const { name, value } = e.target
        if (!(value.length > 600)) {
            setDetails((prev) => {
                return {
                    ...prev,
                    answers: prev.answers.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                [name]: value,
                            }
                        }
                        return item
                    }),
                }
            })
        }
    }

    const handleDetailsGetApi = async () => {
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')

        try {
            const res = await get<Detilas>(
                `${process.env.REACT_APP_PREFIX}/applies/details`,
                {
                    headers: {
                        Authorization: `${tokenType} ${token}`,
                    },
                }
            )

            setDetails(res.data)
            setCareersModel((prev) => {
                const career = res.data.career === '' ? '[]' : res.data.career
                if (JSON.parse(career).length > 0) {
                    return JSON.parse(career)
                }
                return [...prev]
            })

            setCertificateModel((prev) => {
                const cert =
                    res.data.certification === ''
                        ? '[]'
                        : res.data.certification
                if (JSON.parse(cert).length > 0) {
                    return JSON.parse(cert)
                }
                return [...prev]
            })
        } catch (e) {
            const { response } = e as unknown as AxiosError
        }
    }

    const handleDetailPostApi = async () => {
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')

        try {
            setDetails((prev) => {
                return {
                    ...prev,
                    career: JSON.stringify(careersModel),
                    certification: JSON.stringify(certificateModel),
                }
            })
            const careerFilters = careersModel.filter(
                (item) =>
                    dateCheck(item.start_date, 'start_date') ||
                    dateCheck(item.end_date, 'end_date')
            )
            const certificateFilters = certificateModel.filter((item) =>
                dateCheck(item.certification_date, 'certification_date')
            )
            const careerLength = careersModel.filter(
                (item) =>
                    (item.start_date.trim().length > 1 &&
                        item.start_date.trim().length !== 10) ||
                    (item.end_date.trim().length > 1 &&
                        item.end_date.trim().length !== 10)
            )
            console.log('careerLength', careerLength)
            const certificateLength = certificateModel.filter(
                (item) =>
                    item.certification_date.trim().length > 1 &&
                    item.certification_date.trim().length !== 10
            )

            if (careerFilters.length > 0 || careerLength.length > 0) {
                careerLength.forEach((item) => {
                    if (item.start_date.trim().length < 10) {
                        setCareerErrorTypeMessage({
                            id: item.id,
                            type: 'start_date',
                            message: '근무 기간을 확인해주세요.',
                            status: true,
                        })
                    } else if (item.end_date.trim().length < 10) {
                        setCareerErrorTypeMessage({
                            id: item.id,
                            type: 'end_date',
                            message: '근무 기간을 확인해주세요.',
                            status: true,
                        })
                    }
                })
                careerFilters.forEach((item) => {
                    if (dateCheck(item.start_date, 'start_date')) {
                        setCareerErrorTypeMessage({
                            id: item.id,
                            type: 'start_date',
                            message:
                                dateCheck(item.start_date, 'start_date') + '',
                            status: true,
                        })
                    } else if (dateCheck(item.end_date, 'end_date')) {
                        setCareerErrorTypeMessage({
                            id: item.id,
                            type: 'end_date',
                            message: dateCheck(item.end_date, 'end_date') + '',
                            status: true,
                        })
                    }
                })
                return
            }
            if (certificateFilters.length > 0 || certificateLength.length > 0) {
                certificateLength.forEach((item) => {
                    if (item.certification_date.trim().length < 10) {
                        setCertificateErrorTypeMessage({
                            id: item.id,
                            type: 'certification_date',
                            message: '취득일자를 확인해주세요.',
                            status: true,
                        })
                    }
                })
                certificateFilters.forEach((item) => {
                    const message = dateCheck(
                        item.certification_date,
                        'certification_date'
                    )
                    if (
                        dateCheck(item.certification_date, 'certification_date')
                    ) {
                        if (message) {
                            setCertificateErrorTypeMessage({
                                id: item.id,
                                type: 'certification_date',
                                message,
                                status: true,
                            })
                        }
                    }
                })
                return
            }
            await post<Detilas>(
                `${process.env.REACT_APP_PREFIX}/applies/details`,
                {
                    answers: details.answers,
                    career: JSON.stringify(
                        careersModel.filter((item) => {
                            if (
                                item.start_date.length > 0 ||
                                item.end_date.length > 0 ||
                                item.company.length > 0 ||
                                item.work.length > 0
                            ) {
                                return item
                            }
                        })
                    ),

                    certification: JSON.stringify(
                        certificateModel.filter((item) => {
                            if (
                                item.name.length > 0 ||
                                item.certification_no.length > 0 ||
                                item.publish.length > 0 ||
                                item.certification_date.length > 0
                            ) {
                                return item
                            }
                        })
                    ),
                },
                {
                    headers: {
                        Authorization: `${tokenType} ${token}`,
                    },
                }
            )
            onNext()
        } catch (e) {
            const { response } = e as unknown as AxiosError
        }
    }
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const careerCheck = useCallback(() => {
        careersModel.forEach((item) => {
            if (dateCheck(item.start_date, 'start_date')) {
                setCareerErrorTypeMessage({
                    id: item.id,
                    type: 'start_date',
                    message: dateCheck(item.start_date, 'start_date') + '',
                    status: true,
                })
            } else if (dateCheck(item.end_date, 'end_date')) {
                setCareerErrorTypeMessage({
                    id: item.id,
                    type: 'end_date',
                    message: dateCheck(item.end_date, 'end_date') || '',
                    status: true,
                })
            } else {
                setCareerErrorTypeMessage({
                    id: 0,
                    type: '',
                    message: '',
                    status: false,
                })
            }
        })
    }, [careersModel])

    const certificateCheck = () => {
        certificateModel.forEach((item) => {
            const message = dateCheck(
                item.certification_date,
                'certification_date'
            )
            if (message) {
                setCertificateErrorTypeMessage((prev) => ({
                    ...prev,
                    id: item.id,
                    type: 'certification_date',
                    message,
                    status: true,
                }))
            } else {
                setCertificateErrorTypeMessage({
                    type: '',
                    message: '',
                    status: false,
                })
            }
        })
    }
    useEffect(() => {
        certificateCheck()
    }, [certificateModel])

    useEffect(() => {
        careerCheck()
    }, [careersModel])

    useEffect(() => {
        handleDetailsGetApi()
    }, [])

    useEffect(() => {
        const startDateFilter = careersModel.filter((item) =>
            dateCheck(item.start_date, 'start_date')
        )

        const endDateFilter = careersModel.filter((item) =>
            dateCheck(item.end_date, 'end_date')
        )
        if (startDateFilter.length > 0 || endDateFilter.length > 0)
            setValidationErrorCheck(false)
        else setValidationErrorCheck(true)
    }, [careersModel, careersModel.length])

    useEffect(() => {
        if (
            details.answers.filter((item) => item.answer?.length > 0).length ===
                details.answers.length &&
            careerErrorTypeMessage.type === '' &&
            certificateErrorTypeMessage.type === ''
        ) {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }, [careerErrorTypeMessage.type, certificateErrorTypeMessage.type, details])

    useEffect(() => {
        careersModel.forEach((item) => {
            if (
                (item.start_date.length > 0 &&
                    item.start_date.trim().length < 10) ||
                (item.end_date.length > 0 &&
                    item.end_date.trim().length < 10) ||
                (item.end_date.trim().length > 0 &&
                    item.start_date.trim().length < 10)
            ) {
                setValidationErrorCheck(false)
            } else {
                setValidationErrorCheck(true)
            }
        })
    }, [careersModel, careerErrorTypeMessage])

    useEffect(() => {
        certificateModel.forEach((item) => {
            if (
                item.certification_date.length > 0 &&
                item.certification_date.trim().length < 10
            ) {
                setValidationErrorCheck(false)
            } else {
                setValidationErrorCheck(true)
            }
        })
    }, [certificateModel])

    return (
        <Layout>
            {/* <Layout onSubmit={handleSubmit}></Layout> */}
            <div className="title_box">
                <RequireText title="* 표시가 된 칸은 필수로 입력해야 합니다." />
            </div>
            <div className="contents_box">
                <div className="input_form">
                    <div className="input_title_box">
                        <h3 className="title">경력 사항</h3>
                    </div>
                    {careersModel.map((item: Career, idx) => (
                        <InputForm
                            key={item.id}
                            id={item.id}
                            onAdd={handleAddCareer}
                            onCancel={() => handleDeleteCareer(item.id)}
                            length={careersModel.length}
                            // disabled={
                            //     idx !== careersModel.length - 1 ||
                            //     item.status ||
                            //     item.start_date.trim().length < 10 ||
                            //     item.end_date.trim().length < 10 ||
                            //     careerErrorTypeMessage.type !== ''
                            // }
                        >
                            <InputWithTitle
                                title={item.id === 1 ? '근무기간' : ''}
                            >
                                <PatternInput
                                    id={item.id + ''}
                                    width={130}
                                    height={36}
                                    format={'####.##.##'}
                                    placeholder={'2023.01.01'}
                                    onChange={(e) =>
                                        handleChangeCareer(e, item.id)
                                    }
                                    name={`start_date`}
                                    value={item.start_date || ''}
                                    minLength={10}
                                    autoComplete="off"
                                    border={
                                        careerErrorTypeMessage.id === item.id &&
                                        careerErrorTypeMessage.type ===
                                            'start_date'
                                            ? colors.warningColor
                                            : colors.borderGray
                                    }
                                />
                                {careerErrorTypeMessage.id === item.id &&
                                    careerErrorTypeMessage.type ===
                                        'start_date' && (
                                        <ErrorText>
                                            {careerErrorTypeMessage.message}
                                        </ErrorText>
                                    )}

                                <span className="middle_text"> ~ </span>
                                <PatternInput
                                    width={130}
                                    height={36}
                                    format={'####.##.##'}
                                    placeholder={'2023.02.01'}
                                    name={`end_date`}
                                    value={item.end_date || ''}
                                    onChange={(e) =>
                                        handleChangeCareer(e, item.id)
                                    }
                                    autoComplete="off"
                                    border={
                                        careerErrorTypeMessage.id === item.id &&
                                        careerErrorTypeMessage.type ===
                                            'end_date'
                                            ? colors.warningColor
                                            : colors.borderGray
                                    }
                                />
                                {careerErrorTypeMessage.id === item.id &&
                                    careerErrorTypeMessage.type ===
                                        'end_date' && (
                                        <ErrorText>
                                            {careerErrorTypeMessage.message}
                                        </ErrorText>
                                    )}
                            </InputWithTitle>
                            <InputWithTitle
                                title={item.id === 1 ? '근무지' : ''}
                            >
                                <Input
                                    onChange={(e) =>
                                        handleChangeCareer(e, item.id)
                                    }
                                    type={'text'}
                                    name={'company'}
                                    value={item.company || ''}
                                    placeholder="회사명"
                                    height={36}
                                    max={40}
                                />
                            </InputWithTitle>
                            <InputWithTitle
                                title={item.id === 1 ? '담당업무' : ''}
                            >
                                <Input
                                    onChange={(e) =>
                                        handleChangeCareer(e, item.id)
                                    }
                                    type={'text'}
                                    name={'work'}
                                    value={item.work || ''}
                                    width={200}
                                    height={36}
                                    max={40}
                                    placeholder="담당업무를 입력해주세요."
                                    // placeholder={item.id + ''}
                                />
                            </InputWithTitle>
                        </InputForm>
                    ))}
                </div>
                <div>
                    <div className={'input_title_box'}>
                        <h3 className="title">자격증</h3>
                    </div>

                    {certificateModel.map((item) => (
                        <InputForm
                            key={item.id}
                            length={certificateModel.length}
                            id={item.id}
                            onAdd={handleAddCertificate}
                            onCancel={() => handleDeleteCertificate(item.id)}
                        >
                            <InputWithTitle
                                title={item.id === 1 ? '자격증 명' : ''}
                            >
                                <Input
                                    width={200}
                                    onChange={(e) =>
                                        handleChangeCertificate(e, item.id)
                                    }
                                    type={'text'}
                                    name={'name'}
                                    value={item.name}
                                    placeholder="자격증 명"
                                    max={40}
                                />
                            </InputWithTitle>
                            <InputWithTitle
                                title={item.id === 1 ? '등록번호' : ''}
                            >
                                <Input
                                    onChange={(e) =>
                                        handleChangeCertificate(e, item.id)
                                    }
                                    type={'text'}
                                    name={'certification_no'}
                                    height={36}
                                    value={item.certification_no}
                                    placeholder="등록번호"
                                    max={40}
                                />
                            </InputWithTitle>
                            <InputWithTitle
                                title={item.id === 1 ? '발행기관' : ''}
                            >
                                <Input
                                    onChange={(e) =>
                                        handleChangeCertificate(e, item.id)
                                    }
                                    type={'text'}
                                    name={'publish'}
                                    height={36}
                                    value={item.publish}
                                    placeholder="발행기관"
                                    max={40}
                                />
                            </InputWithTitle>
                            <InputWithTitle
                                title={item.id === 1 ? '취득일자' : ''}
                            >
                                <PatternInput
                                    id={item.id + ''}
                                    width={130}
                                    height={36}
                                    format={'####.##.##'}
                                    placeholder={'2023.01.01'}
                                    onChange={(e) =>
                                        handleChangeCertificate(e, item.id)
                                    }
                                    name={'certification_date'}
                                    value={item.certification_date || ''}
                                    autoComplete="off"
                                    border={
                                        certificateErrorTypeMessage.id ===
                                            item.id &&
                                        certificateErrorTypeMessage.type ===
                                            'certification_date'
                                            ? colors.warningColor
                                            : colors.borderGray
                                    }
                                />
                                {certificateErrorTypeMessage.id === item.id &&
                                    certificateErrorTypeMessage.type ===
                                        'certification_date' && (
                                        <ErrorText>
                                            {
                                                certificateErrorTypeMessage.message
                                            }
                                        </ErrorText>
                                    )}
                            </InputWithTitle>
                        </InputForm>
                    ))}
                </div>
                {details.answers.map((item) => (
                    <TextAreaBox key={item.id}>
                        <TextArea
                            title={item.question}
                            maxLength="600"
                            require={true}
                            name={'answer'}
                            value={item.answer !== null ? item.answer : ''}
                            // value={item.answer}
                            onChange={(e) => handleAnswer(e, item.id)}
                        />
                    </TextAreaBox>
                ))}
            </div>
            <ButtonBox>
                <PrevWithNextArea
                    onNext={() => {
                        handleDetailPostApi()
                    }}
                    onPrev={onPrev}
                    success={
                        success &&
                        validationErrorCheck &&
                        careerErrorTypeMessage.type === ''
                    }
                />
            </ButtonBox>
        </Layout>
    )
}

export default SelfIntroduction
const Layout = styled.div`
    padding: 0 50px;
    .title_box {
        margin-bottom: 20px;
    }
    .contents_box {
        display: flex;
        flex-direction: column;
    }
    .input_form {
        margin-bottom: 40px;
    }
    .title {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
    }
    .input_title_box {
        margin-bottom: 14px;
    }
    .middle_text {
        margin: 0 12px;
    }
`

const TextAreaBox = styled.div`
    margin: 40px 0 12px 0;
`

const ButtonBox = styled.div`
    margin: 80px 0 60px 0;
`

const PatternInput = styled(PatternFormat)<{ border?: string }>`
    width: ${({ width }) => (width ? width : 130)}px;
    height: ${({ height }) => (height ? height : 40)}px;

    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
    border: 2px solid ${({ border }) => border && border};
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;

    outline: none;
    box-sizing: border-box;
    &::placeholder {
        color: ${colors.borderGray};
    }
    &::disabled {
        background: ${colors.disabledColor};
        border: 0;
        cursor: not-allowed;
    }
    &:focus {
        border: 2px solid ${colors.subColor};
    }
`
const ErrorText = styled.p`
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.warningColor};
`
