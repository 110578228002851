import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import PrevWithNextArea from './PrevWithNextArea'
import SelectionCheck from '../elements/SelectionCheck'
import { get, patch } from '../../api/customAxios'
import ModalPortal from '../elements/ModalPortal'
import Modal from '../Modal'
import YoungPeople from './modal/YoungPeople'
import CareerInterrupted from './modal/CareerInterrupted'
import NearPoverty from './modal/NearPoverty'
import Handicapped from './modal/Handicapped'
import MultiCultural from './modal/MultiCultural'
import { AxiosError } from 'axios'
import { ErrorMessage } from '../../types/Error'
import SelfCheck from './modal/SelfCheck'
import { useAppSelector } from '../../store'
import { checkSelection } from './userSelectionCheck'
import colors from '../../utils/colors'

interface AvailableList {
    apply_type_no: number
    apply_type_name: string
    is_selected: boolean
}
interface Selected {
    birth_date: string
    available_list: AvailableList[]
    is_submit: boolean
}
interface Props {
    onNext: () => void
    onPrev: () => void
}

const UserSelection: React.FC<Props> = ({ onNext, onPrev }) => {
    // const applyTypes = useAppSelector((state) => state.recruitTypes).applyTypes
    const [success, setSuccess] = useState(false)
    const [typeNo, setTypeNo] = useState(0)
    const [availableList, setAvailableList] = useState<Selected>()
    const [helpShowModal, setHelpShowModal] = useState(false)
    const [helpTypeNo, setHelpTypeNo] = useState(0)
    const [selfCheckNo, setSelfCheckNo] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const currentPage =
        sessionStorage.getItem('currentPage') !== null
            ? sessionStorage.getItem('currentPage')
            : {}
    const handleCheck = (id: number) => {
        setTypeNo(id)
        setSuccess(true)
    }

    const handleHelpShow = (id: number) => {
        setHelpShowModal((prev) => !prev)
        setHelpTypeNo(id)
    }

    const handleGetApi = useCallback(async () => {
        // TODO
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')

        try {
            const res = await get<Selected>(
                `${process.env.REACT_APP_PREFIX}/applies/types`,
                {
                    headers: {
                        Authorization: `${tokenType} ${token}`,
                    },
                }
            )
            setAvailableList(res.data)

            setTypeNo(() => {
                return res.data.available_list.filter(
                    (item) => item.is_selected
                )[0]?.apply_type_no
            })
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }, [typeNo])

    const handleNext = async () => {
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')

        try {
            await patch(`${process.env.REACT_APP_PREFIX}/applies`, typeNo, {
                headers: {
                    Authorization: `${tokenType} ${token}`,
                },
            })
            onNext()
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }

    // 기존 모달의 번호를 자가 진단 번호로 넘기고 창을 바꿈
    const setShowSelfCheck = () => {
        setSelfCheckNo(helpTypeNo)
        setHelpTypeNo(6)
    }

    useEffect(() => {
        handleGetApi()
    }, [])

    useEffect(() => {
        if (typeNo) {
            const selectedRow = availableList?.available_list.find(
                (row) => row.apply_type_no === typeNo
            )
            if (availableList?.is_submit) {
                setSuccess(true)
            } else {
                if (selectedRow && availableList) {
                    if (
                        checkSelection(
                            selectedRow.apply_type_no,
                            availableList.birth_date,
                            sessionStorage.getItem('announce_date')
                        )
                    ) {
                        setSuccess(true)
                    }
                } else {
                    setSuccess(false)
                }
            }
        }
    }, [typeNo, availableList?.available_list])

    return (
        <Layout>
            <SelectionForm>
                {availableList?.available_list.map((item) => (
                    <>
                        <SelectionCheck
                            key={item.apply_type_no}
                            title={item.apply_type_name}
                            typeNo={typeNo}
                            id={item.apply_type_no}
                            disabled={
                                availableList.is_submit ||
                                !checkSelection(
                                    item.apply_type_no,
                                    availableList.birth_date,
                                    sessionStorage.getItem('announce_date')
                                )
                            }
                            onClick={handleCheck}
                            onHelp={handleHelpShow}
                        />
                    </>
                ))}
            </SelectionForm>
            <WarningGuide>
                <ol>
                    <li>
                        지원 전형을 잘못 선택하거나, 필수 제출 서류에 대한
                        미제출로 인해 자격 미충족이 발생하는 경우, 책임은 지원자
                        본인에게 있습니다.
                    </li>
                    <li>
                        지원 완료 시, 지원 전형 변경이 불가능하오니 지원 전형을
                        다시 한번 확인해 주시기 바랍니다.
                    </li>
                </ol>
            </WarningGuide>
            <PrevWithNextArea
                onNext={handleNext}
                onPrev={onPrev}
                success={success}
            />
            {helpShowModal && (
                <ModalPortal>
                    <Modal setShowModal={() => {}}>
                        {helpTypeNo === 1 && (
                            <YoungPeople
                                setShowModal={setHelpShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {helpTypeNo === 2 && (
                            <CareerInterrupted
                                setShowModal={setHelpShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {helpTypeNo === 3 && (
                            <NearPoverty
                                setShowModal={setHelpShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {helpTypeNo === 4 && (
                            <Handicapped
                                setShowModal={setHelpShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {helpTypeNo === 5 && (
                            <MultiCultural
                                setShowModal={setHelpShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {helpTypeNo === 6 && (
                            <SelfCheck
                                applyTypeNo={selfCheckNo}
                                setShowModal={setHelpShowModal}
                            />
                        )}
                    </Modal>
                </ModalPortal>
            )}
        </Layout>
    )
}

export default UserSelection

const Layout = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 93px 0 60px 0;
    .icon_question {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('/icon/Question.svg');
    }
`

const SelectionForm = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
    gap: 24px;
    width: 500px;
    height: 168px;
    margin-bottom: 194px;
`

const WarningGuide = styled.div`
    max-width: 612px;
    margin-bottom: 16px;
    ol {
        list-style-type: decimal;
        li {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            color: ${colors.warningSubColor};
        }
    }
`
