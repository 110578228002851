import React, { useEffect, useState } from 'react'
import TypeButton from '../elements/TypeButton'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { ErrorMessage, ErrorType } from '../../types/Error'
import colors from '../../utils/colors'
import InputGroup from '../elements/InputGroup'
import Input from '../elements/Input'
import useInputs from '../../hooks/useInputs'
import ModalPortal from '../elements/ModalPortal'
import Modal from '../Modal'
import InquiryModal from './modal/InquiryModal'
import { get } from '../../api/customAxios'
import { Acceptance } from '../../types/Recruit'
import { AxiosError } from 'axios'
import { keyframes } from '@emotion/react'

const Inquiry = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showModal, setShowModal] = useState(false)
    const [acceptance, setAcceptance] = useState<Acceptance>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [formModel, handleChangeFormModel, setFormModel] = useInputs({
        recruit_no: 0,
        user_email: '',
        user_name: '',
    })
    // 정보 입력 잘못했을 경우의 에러메세지
    const [errorTypeMessage, setErrorTypeMessage] = useState<ErrorType>({
        type: '',
        message: '',
        status: false,
    })

    // 이메일 검증 관련
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const handleEmailConfirm = () => {
        if (formModel.user_email && formModel.user_email.match(isValidEmail)) {
            return true
        } else {
            return false
        }
    }

    const handleInquriyApi = async () => {
        try {
            setIsLoading(true)
            const res = await get<Acceptance>(
                `${process.env.REACT_APP_PREFIX}/applies/acceptance/${params.id}`,
                {
                    params: {
                        user_name: formModel.user_name,
                        user_email: formModel.user_email,
                    },
                },
            )
            if (res.data) {
                setShowModal(true)
                setAcceptance(res.data)
            }
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        } finally {
            setIsLoading(false)
        }
    }

    const handelInqury = async () => {
        if (!formModel.user_name) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'user_name',
                message: '이름을 입력해주세요.',
            }))
            return
        }
        if (!formModel.user_email) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'user_email',
                message: '이메일을 입력해주세요.',
            }))
            return
        }
        if (!handleEmailConfirm()) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'user_email',
                message: '올바른 이메일 주소가 아닙니다.',
            }))
            return
        }
        setErrorTypeMessage((prev) => ({
            ...prev,
            type: '',
            message: '',
        }))
        await handleInquriyApi()
    }

    useEffect(() => {
        // handleResultGetApi()
    }, [])

    return (
        <Layout>
            <h2 className="title">
                합격자 조회
                <span>* 표시가 된 칸은 필수로 입력해야 합니다.</span>
            </h2>
            <div className="form">
                <InputGroup
                    title="이름"
                    require={true}
                    margin={'0'}
                    labelWidth={40}
                >
                    <div>
                        <Input
                            onChange={handleChangeFormModel}
                            width={250}
                            max={20}
                            min={2}
                            type={'text'}
                            name={'user_name'}
                            value={formModel.user_name}
                            placeholder="이름을 입력해주세요"
                            line={
                                errorTypeMessage.type === 'user_name'
                                    ? `2px solid ${colors.warningColor}`
                                    : `2px solid ${colors.borderGray}`
                            }
                            required
                        />
                        {errorTypeMessage.type === 'user_name' && (
                            <ErrorText>{errorTypeMessage.message}</ErrorText>
                        )}
                    </div>
                </InputGroup>
                <InputGroup title="이메일" require={true} margin={'0'}>
                    <div>
                        <Input
                            onChange={handleChangeFormModel}
                            width={250}
                            max={45}
                            min={2}
                            type={'text'}
                            name={'user_email'}
                            value={formModel.user_email}
                            placeholder="이메일을 입력해주세요"
                            line={
                                errorTypeMessage.type === 'user_email'
                                    ? `2px solid ${colors.warningColor}`
                                    : `2px solid ${colors.borderGray}`
                            }
                            required
                        />
                        {errorTypeMessage.type === 'user_email' && (
                            <ErrorText>{errorTypeMessage.message}</ErrorText>
                        )}
                    </div>
                </InputGroup>
                <TypeButton
                    icon={false}
                    sort={false}
                    disabled={isLoading}
                    onClick={() => {
                        handelInqury()
                    }}
                    size={isLoading ? 120 : 100}
                    type={'confirm'}
                    margin={'0 0 0 40px'}
                >
                    조회
                    {isLoading && (
                        <IndicatorIcon
                            style={{ marginLeft: 10, verticalAlign: 'bottom' }}
                        />
                    )}
                </TypeButton>
            </div>
            {showModal && (
                <ModalPortal>
                    <Modal setShowModal={() => {}}>
                        <InquiryModal
                            acceptance={acceptance}
                            setShowModal={setShowModal}
                        />
                    </Modal>
                </ModalPortal>
            )}
        </Layout>
    )
}

export default Inquiry

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
    .title {
        width: 100%;
        font-weight: 600;
        color: ${colors.textBoldColor};
        line-height: 21px;
        font-size: 18px;
        span {
            margin-left: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #ff5b48;
        }
    }

    .form {
        display: flex;
        flex-direction: row;
        margin: 20px 0 0 0;
        align-items: flex-start;
        // justify-content: flex-start;
        width: 100%;
    }

    .require {
        font-size: 16px;
        font-weight: 700;
        color: ${colors.warningSubColor};
    }
`

const ErrorText = styled.p`
    margin: 6px 0 0 14px;
    height: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.warningColor};
`

const IndicatorKeyFramse = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const IndicatorIcon = styled.i`
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: url('/icon/IndicatorIcon.svg');
    background-size: cover;
    animation: ${IndicatorKeyFramse} 1s infinite linear;
`
