import React from 'react'
import TypeButton from '../elements/TypeButton'
import styled from '@emotion/styled'

interface Props {
    length: number
    onAdd: () => void
    onCancel: () => void
    children?: React.ReactNode
    padding?: string
    id: number
    disabled?: boolean
}
const InputForm: React.FC<Props> = ({
    length,
    onAdd,
    onCancel,
    children,
    padding,
    id,
    disabled,
}) => {
    return (
        <Layout padding={padding}>
            <ChildBox>{children}</ChildBox>
            <ButtonBox>
                <TypeButton
                    onClick={onAdd}
                    icon={false}
                    sort={false}
                    type={'confirm'}
                    height={30}
                    size={70}
                    disabled={disabled}
                >
                    추가
                </TypeButton>
                {id > 1 && length > 0 ? (
                    <TypeButton
                        size={70}
                        height={30}
                        type="cancel"
                        onClick={onCancel}
                        icon={false}
                        sort={false}
                    >
                        삭제
                    </TypeButton>
                ) : null}
            </ButtonBox>
        </Layout>
    )
}

export default InputForm

const Layout = styled.div<{ padding?: string }>`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: ${({ padding }) => padding};
`
const ChildBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 700px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    max-width: 150px;
    width: 100%;
`
