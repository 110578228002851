import { useCallback, useState } from 'react'

const useInputs = (initialState: any) => {
    const [value, setValue] = useState<typeof initialState>(initialState)
    const handler = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue((prev: typeof initialState) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value,
                }
            })
        },
        [value]
    )
    return [value, handler, setValue]
}

export default useInputs
