import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Fragment, useEffect, useState } from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import './indicator.css'
import RoundCheckIcon from './icon/RoundCheckIcon'
import colors from '../utils/colors'

interface Props {
    isLoading?: boolean
    top?: number
}

const Indicator: React.FC<Props> = ({ isLoading, top }) => {
    const [isShowUi, setIsShowUi] = useState(false) // UI가 보일지 여부

    useEffect(() => {
        if (isLoading) {
            setIsShowUi(true)
        } else {
            setTimeout(() => {
                setIsShowUi(false)
            }, 500)
        }
    }, [isLoading])

    return (
        <Fragment>
            {isShowUi && (
                <CSSTransition
                    in={isShowUi}
                    timeout={{ enter: 500, exit: 500 }}
                    classNames="fade"
                >
                    <Layout top={top}>
                        <Contents>
                            <StateText>
                                {!isLoading
                                    ? '완료되었습니다.'
                                    : '처리중입니다.'}
                            </StateText>
                            {!isLoading ? (
                                <RoundCheckIcon width={22} height={22} />
                            ) : (
                                <IndicatorIcon />
                            )}
                        </Contents>
                    </Layout>
                </CSSTransition>
            )}
        </Fragment>
    )
}

export default Indicator

const Layout = styled.div<{ top?: number }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    top: ${({ top }) => top}px;
`
const Contents = styled.div`
    position: absolute;
    bottom: 120px;
    display: flex;
    align-items: center;
    gap: 6px;
`
const StateText = styled.div`
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: ${colors.inputTextColor};
`

const IndicatorKeyFramse = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const IndicatorIcon = styled.i`
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: url('/icon/IndicatorIcon.svg');
    background-size: cover;
    animation: ${IndicatorKeyFramse} 1s infinite linear;
`
