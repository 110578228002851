import React from 'react'

import styled from '@emotion/styled'
import Banner from './elements/Banner'

interface Props {
    children: React.ReactNode
    bgColor?: string
    src?: string
}
const AppLayout: React.FC<Props> = ({ children, bgColor, src }) => {
    return (
        <Layout>
            <Banner
                bgColor={bgColor}
                height={260}
                src={src || '/image/RecruitBanner.png'}
            />
            <Contents>{children}</Contents>
        </Layout>
    )
}

export default AppLayout

const Layout = styled.div`
    width: 100%;

    margin: 0 auto;
`

const Nav = styled.nav`
    width: 1000px;
    margin: 0 auto;
`

const Contents = styled.div`
    width: 1000px;
    margin: 0 auto;
`
