import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface Props {
    children?: React.ReactNode
    setShowModal: Dispatch<SetStateAction<boolean>>

    contentsWidth?: number
    contentsHeight?: number
}
const Modal: React.FC<Props> = ({ children, setShowModal }) => {
    const [visible, setVisible] = useState<boolean>(false)
    useEffect(() => {
        setVisible(true)
    }, [])
    const closeModal = () => {
        setVisible(false)
        setTimeout(() => {
            setShowModal(false)
        }, 300)
    }
    // onClick={closeModal}
    return <Layout visible={visible}>{children}</Layout>
}

export default Modal
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const modalSettings = (visible: boolean) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
    z-index: 15;
    animation: ${visible ? fadeIn : fadeOut} 0.5s ease-out;
    transition: visibility 0.15s ease-out;
`
const Layout = styled.div<{ visible: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(235, 235, 235, 0.8);
    box-sizing: border-box;
    .post_address {
        margin: 0 auto;
        max-width: 400px;
        height: 400px;
    }
    z-index: 1;
    ${(props) => modalSettings(props.visible)}
`
