import styled from '@emotion/styled'
import dayjs from 'dayjs'
import React from 'react'
import { useAppSelector } from '../../../store'
import { Acceptance } from '../../../types/Recruit'
import TypeButton from '../../elements/TypeButton'
import SelfAppraisal from '../SelfAppraisal'
import ModalStyled from './ModalStyled'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    acceptance?: Acceptance
}
const InquiryModal: React.FC<Props> = ({ setShowModal, acceptance }) => {
    const { recruitDetail } = useAppSelector((state) => state.recruitTypes)
    const timeFormat = (date: string) => {
        // 면접 일정 : 2023.02.28 오후 02:00
        let hour = Number(dayjs(date).format('HH'))
        let ampm = ''
        // let hStr = h
        if (hour >= 12) {
            hour -= 12
            ampm = '오후'
        } else {
            ampm = '오전'
        }
        return dayjs(date).format(
            `YYYY.MM.DD ${ampm} ${hour < 10 ? '0' + hour : hour}:mm`,
        )
    }

    const now = dayjs()
    const isSecond = now.diff(dayjs(recruitDetail?.second_start_date)) > 0
    const isFinal = now.diff(dayjs(recruitDetail?.announce_date)) > 0

    return (
        <ModalStyled>
            <SelfAppraisal subject={'합격자 조회'} setShowModal={setShowModal}>
                <Content>
                    {/* 1 = 작성중 제출하지 않음 */}
                    {['1'].indexOf(acceptance!.apply_status_no) !== -1 && (
                        <>
                            <div className="icon">
                                <WarnIcon />
                            </div>
                            지원서 제출이 완료되지 않아
                            <br />
                            조회가 불가능합니다.
                        </>
                    )}
                    {/* 2~5 제출 완료 및 재작성 */}
                    {['2', '3', '4', '5'].indexOf(
                        acceptance!.apply_status_no,
                    ) !== -1 && (
                        <>
                            <div className="icon">
                                <WarnIcon />
                            </div>
                            아직 심사를 진행하지 않았거나 심사 진행 중입니다.
                            <br />
                            심사가 완료된 이후 조회 해주세요.
                        </>
                    )}
                    {/* 6 = 1차 합격 */}
                    {!isSecond &&
                        ['6'].indexOf(acceptance!.apply_status_no) !== -1 && (
                            <>
                                <div className="icon tit">
                                    <DtIcon />
                                </div>
                                <div className="title">1차 합격</div>
                                <div className="small">
                                    서류 전형 합격을 진심으로 축하드리며,
                                    <br />
                                    아래와 같이 면접 일정을 알려드립니다.
                                </div>
                                <div className="interview">
                                    면접 일정 :{' '}
                                    {timeFormat(acceptance!.interview_date)}
                                </div>
                            </>
                        )}
                    {/* 7 = 1차 불합격 */}
                    {['7'].indexOf(acceptance!.apply_status_no) !== -1 && (
                        <>
                            <div className="icon tit">
                                <DtIcon />
                            </div>
                            <div className="title">1차 불합격</div>
                            <div className="small">
                                보내주신 이력서와 서류를 검토해 본 결과,
                                <br />
                                이번에는 함께 할 수 없게 되었음을 안내드립니다.
                            </div>
                        </>
                    )}
                    {/* 최종합격 되었으나 발표전 */}
                    {isSecond &&
                        !isFinal &&
                        ['6', '8', '9', '10'].indexOf(
                            acceptance!.apply_status_no,
                        ) !== -1 && (
                            <>
                                <div className="icon tit">
                                    <DtIcon />
                                </div>
                                <div className="title">최종심사 진행중</div>
                                <div className="small">
                                    최종심사일 이후에 조회가 가능합니다.
                                    <br />
                                    {dayjs(recruitDetail?.announce_date).format(
                                        'YYYY년 MM월 DD일 HH:mm',
                                    )}
                                    부터 조회해주시기 바랍니다.
                                </div>
                            </>
                        )}
                    {isSecond && isFinal && (
                        <>
                            {/* 6=1차 합격*/}
                            {['6'].indexOf(acceptance!.apply_status_no) !==
                                -1 && (
                                <>
                                    <>
                                        <div className="icon tit">
                                            <DtIcon />
                                        </div>
                                        <div className="title">
                                            최종심사 진행중
                                        </div>
                                        <div className="small">
                                            최종심사일 이후에 조회가 가능합니다.
                                            <br />
                                            {dayjs(
                                                recruitDetail?.announce_date,
                                            ).format('YYYY년 MM월 DD일 HH:mm')}
                                            부터 조회해주시기 바랍니다.
                                        </div>
                                    </>
                                </>
                            )}
                            {/* 8 = 최종합격 */}
                            {['8'].indexOf(acceptance!.apply_status_no) !==
                                -1 && (
                                <>
                                    <div className="icon tit">
                                        <DtIcon />
                                    </div>
                                    <div className="title">최종 합격</div>
                                    <div className="small">
                                        최종 합격을 진심으로 축하드리며,
                                        <br />
                                        추후 업무 일정에 대한 안내드립니다.
                                    </div>
                                </>
                            )}
                            {/* 9 = 최종예비합격 */}
                            {['9'].indexOf(acceptance!.apply_status_no) !==
                                -1 && (
                                <>
                                    <div className="icon tit">
                                        <DtIcon />
                                    </div>
                                    <div className="title">최종 예비 합격</div>
                                    <div className="small">
                                        예비합격자의 경우 합격자 중 입사 포기
                                        또는 퇴사로 인해
                                        <br />
                                        실무자 충원이 필요할 시 예비 순위에 따라
                                        선정되며,
                                        <br />
                                        선정되신 예비 합격자 분께는 충원이
                                        필요할 시
                                        <br />
                                        개별적으로 연락을 드릴 예정입니다.
                                    </div>
                                </>
                            )}
                            {/* 10 = 최종 불합격 */}
                            {['10'].indexOf(acceptance!.apply_status_no) !==
                                -1 && (
                                <>
                                    <div className="icon tit">
                                        <DtIcon />
                                    </div>
                                    <div className="title">최종 불합격</div>
                                    <div className="small">
                                        귀하께서는 우수한 능력과 열정을
                                        보여주셨습니다만,
                                        <br />
                                        당사의 제한된 인력 계획으로 인해 인연을
                                        맺지 못하게 된 점을
                                        <br />
                                        매우 유감스럽게 생각합니다.
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Content>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%',
                        bottom: 38,
                        left: 0,
                    }}
                >
                    <TypeButton
                        type={'cancel'}
                        onClick={() => {
                            setShowModal(false)
                        }}
                        icon={false}
                        sort={true}
                        size={100}
                        fSize={18}
                    >
                        닫기
                    </TypeButton>
                </div>
            </SelfAppraisal>
        </ModalStyled>
    )
}

export default InquiryModal
const Ul = styled.ul`
    list-style: disc;
    li {
        list-style: disc;
        margin-left: 20px;
    }
`
const Content = styled.div`
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #333333;

    .icon {
        width: 100%;
        margin-bottom: 40px;
        &.tit {
            margin-bottom: 20px;
        }
    }

    .title {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: #333333;
    }
    .small {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #333333;
    }
    .interview {
        margin-top: 14px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-align: center;

        color: #f94733;
    }
`

const FailIcon = styled.i`
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url('/icon/SelfCheckFail.svg');
    background-size: 100% 100%;
`
const SuccessIcon = styled.i`
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url('/icon/SelfCheckSuccess.svg');
    background-size: 100% 100%;
`
const WarnIcon = styled.i`
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url('/icon/SelfCheckWarn.svg');
    background-size: 100% 100%;
`

const DtIcon = styled.i`
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url('/icon/SelfCheckDt.svg');
    background-size: 100% 100%;
`
