import styled from '@emotion/styled'
import React from 'react'
import { OperationInfo } from '../../types/Operation'
import colors from '../../utils/colors'
import SingleCheckIcon from '../icon/SingleCheckIcon'

const agreementText = `<h1>개인정보처리방침</h1>

< 주식회사 갤럭틱엔터테인먼트 >(이하 '(주)갤럭틱엔터테인먼트')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

○ 이 개인정보처리방침은 2024년 1월 1부터 적용됩니다.

<h2>제1조(개인정보의 처리 목적)</h2>
< (주)갤럭틱엔터테인먼트 > 는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
1. 인사업무 처리
(주)갤럭틱엔터테인먼트 주관 하 주요 공공기관의 위탁 사업으로 인한 기간제 근로자 채용에 따른
채용 서비스 제공 및 인사·급여 업무 처리를 목적으로 개인정보를 처리합니다.

<h2>제2조(개인정보의 처리 및 보유 기간)</h2>
① < (주)갤럭틱엔터테인먼트 > 는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
1와 관련한 개인정보는 수집·이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.
보유근거 : 정보주체의 동의
보유기간 : 동의일로부터 1년

<h2>제3조(처리하는 개인정보의 항목)</h2>
① < (주)갤럭틱엔터테인먼트 > 는 다음의 개인정보 항목을 처리하고 있습니다.
1< 인사업무 처리 >
필수항목 : 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 서비스 이용 기록

<h2>제4조(개인정보의 제3자 제공에 관한 사항)</h2>
① < (주)갤럭틱엔터테인먼트 > 는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
② < (주)갤럭틱엔터테인먼트 > 는 본 처리방침 작성 시점에서 제3자에게 개인정보를 제공하고 있지 않습니다.`

interface Props {
    operationInfo: OperationInfo
    change: Function
    setter: Function
}
const InfoAgree: React.FC<Props> = ({ operationInfo, change, setter }) => {
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setter((prev: OperationInfo) => {
            return { ...prev, [name]: checked }
        })
    }

    return (
        <Layout>
            <div className="agree">
                <Label>
                    <CheckBox>
                        {operationInfo.agreement && (
                            <SingleCheckIcon width={18} height={18} />
                        )}
                    </CheckBox>
                    <Input2
                        name={'agreement'}
                        id={'agreement'}
                        type={'checkbox'}
                        // checked={operationInfo.medical_benefit_yn}
                        onChange={handleCheck}
                    />
                    서비스 이용약관 (필수)
                </Label>

                <div>
                    <ShowAll
                        href="https://docs.google.com/document/d/14euKDqxXj5ncFPxxYXtuTwU_X6lo7bKs6MZjQ0xtrcQ/view"
                        target="_blank"
                    >
                        전문보기
                    </ShowAll>
                </div>
            </div>
            <div
                className="agreement"
                dangerouslySetInnerHTML={{
                    __html: agreementText.replaceAll('\n', '<br/>'),
                }}
            ></div>
        </Layout>
    )
}

export default React.memo(InfoAgree)

const Layout = styled.div`
    // overflow-x: scroll;
    // width: 300px;
    // height: 330px;
    padding: 16px 16px;

    box-sizing: border-box;

    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: ${colors.textBoldColor};

    .agree {
        display: flex;
        justify-content: space-between;
    }
    .agreement {
        box-sizing: border-box;
        width: 100%;
        height: 240px;
        padding: 16px;
        margin-top: 16px;

        background: #fefefe;
        border: 2px solid #ebebeb;
        overflow: auto;
        h1 {
            margin: 0;
            font-weight: 600;
            font-size: 18px;
        }
        h2 {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
        }
    }
`
const Label = styled.label`
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #333333;
    margin-right: 12px;
`

const CheckBox = styled.div`
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid ${colors.mainColor};
    border-radius: 2px;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 12px;
`

const Input2 = styled.input`
    display: none;
`

const ShowAll = styled.a`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.003em;
    color: #478bea;
    text-decoration: none;
`
