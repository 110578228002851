import styled from '@emotion/styled'
import React from 'react'
import colors from '../../utils/colors'

interface Props {
    children: React.ReactNode
    icon: boolean
    sort: boolean
    fSize?: number
    onClick: () => void
    type: 'confirm' | 'cancel'
    size?: number
    disabled?: boolean
    margin?: string
    height?: number
}
const TypeButton: React.FC<Props> = ({
    children,
    icon,
    sort,
    fSize,
    onClick,
    type,
    size = 150,
    disabled,
    margin,
    height,
}) => {
    const colorType = (type: 'confirm' | 'cancel') => {
        if (type === 'confirm')
            return 'linear-gradient(180deg, #2278f1 0%, #1465d7 100%)'
        if (type === 'cancel')
            return 'linear-gradient(180deg, #FEFEFE 0%, #FCFCFC 100%)'
        return ''
    }

    return (
        <Layout sort={sort} fSize={fSize} margin={margin}>
            <Button
                size={size}
                icon={icon}
                types={type}
                background={colorType(type)}
                onClick={onClick}
                type={type === 'confirm' ? 'submit' : 'button'}
                disabled={disabled}
                height={height}
            >
                <span>{children}</span>
                {icon && <i className="icon_question" />}
            </Button>
        </Layout>
    )
}

export default React.memo(TypeButton)

const Layout = styled.div<{
    sort: boolean
    fSize?: number
    margin?: string
    // background: string
    // type: 'confirm' | 'cancel'
    // size: number
    // icon: boolean
}>`
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    // position: relative;
    display: flex;
    align-items: center;
    justify-content: ${({ sort }) => (sort ? 'center' : 'space-between')};
    margin: ${({ margin }) => margin};

    .icon_question {
        // position: absolute;
        // right: 10px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('/icon/Question.svg');
    }
`

const Button = styled.button<{
    size: number
    icon: boolean
    types: 'confirm' | 'cancel'
    fSize?: number
    background: string
    height?: number
}>`
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: ${({ size }) => size}px;
    height: ${({ height }) => (height ? height : 40)}px;
    padding: ${({ icon }) => icon && '0 15px'};
    border: ${({ types }) =>
        types === 'confirm' ? '0' : `2px solid ${colors.mainColor}`};
    border-radius: 4px;
    background: ${({ background }) => background};
    box-sizing: border-box;
    cursor: pointer;

    font-size: ${({ fSize }) => (fSize ? fSize : 16)}px;
    font-weight: 600;
    color: ${({ types }) =>
        types === 'confirm' ? '#fefefe' : `${colors.mainColor}`};

    &:disabled {
        cursor: not-allowed;
        background: ${colors.disabledColor};
    }
    &:hover:enabled {
        // background: linear-gradient(180deg, #2278f1 0%, #1465d7 100%);
        filter: drop-shadow(0px 0px 3px rgba(71, 139, 234, 0.8));
    }
    &:active:enabled {
        background: ${({ types }) =>
            types === 'confirm'
                ? colors.subColor
                : ' linear-gradient(180deg, #FEFEFE 0%, #FCFCFC 100%)'};
        border: ${({ types }) =>
            types === 'cancel' && `2px solid ${colors.subColor}`};
        filter: none;
        color: ${({ types }) => types === 'cancel' && colors.subColor};
    }
`
