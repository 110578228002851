import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import typeSlice from './slice/typeSlice'

const rootReducer = combineReducers({
    recruitTypes: typeSlice,
})

const initialState = {}
const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
