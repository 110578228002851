import dayjs from 'dayjs'

/*
### 청년 = 1

- 주민번호 1,2,3,4
- 주민번호 1,3(남자) 인 경우
    - ~~34세 이하 - 별도 제한 없음~~
    - ~~35세 이상 ~ 37세 이하~~ ⇒ 37세 이하
    - 나이에 대한 두 케이스에 대해 실제 전형 입력에는 제한을 두지 않음
        - 군경력증명서 필수 첨부 처리 가능한지? → 전형 이후에 첨부파일에 분기 (형욱님 한테 전달필요)
        - 또는 지원자 리스트에서 해당 인원들 표기만 가능하도록 건의
- 주민번호 2,4(여자) 인경우
    - 만 36세 이하

 

### 경단녀 = 2

- 주민번호 2,4

### 다문화 = 5

- 주민번호 1,2,3,4,5,6,7,8

### 장애인 = 4

- 주민번호 1,2,3,4

### 차상위 = 3

- 주민번호 1,2,3,4
*/
export const checkSelection = (
    applyTypeNo: number,
    birthDate: string,
    finalDate: string | null,
) => {
    if (!finalDate) return false
    const [firstNumber, lastNumber] = birthDate.split('-')
    const firstYear =
        ['1', '2', '5', '6'].indexOf(lastNumber) !== -1 ? '19' : '20'
    const birth = dayjs(firstYear + firstNumber, 'YYYYMMDD')
    const final = dayjs(finalDate, 'YYYY-MM-DD')
    const age = final.diff(birth, 'year')
    if (age < 19) return false
    // if (age > 60 && applyTypeNo !== 4) return false

    // 청년
    //     - 주민번호 1,3(남자) 인 경우
    //     - ~~34세 이하 - 별도 제한 없음~~
    //     - ~~35세 이상 ~ 37세 이하~~ ⇒ 37세 이하
    //     - 나이에 대한 두 케이스에 대해 실제 전형 입력에는 제한을 두지 않음
    //         - 군경력증명서 필수 첨부 처리 가능한지? → 전형 이후에 첨부파일에 분기 (형욱님 한테 전달필요)
    //         - 또는 지원자 리스트에서 해당 인원들 표기만 가능하도록 건의
    // - 주민번호 2,4(여자) 인경우
    //     - 만 36세 이하
    if (applyTypeNo === 1) {
        if (['1', '3'].indexOf(lastNumber) !== -1) {
            // 37세 이하인가?
            if (age <= 37) return true
            else return false
        }
        if (['2', '4'].indexOf(lastNumber) !== -1) {
            // 37세 이하인가?
            if (age <= 36) return true
            else return false
        }
        return false
    }
    // 경단녀
    // 주민번호 2,4
    if (applyTypeNo === 2) {
        if (['2', '4'].indexOf(lastNumber) === -1) return false
        return true
    }
    // 차상위 = 3
    // 주민번호 1,2,3,4
    if (applyTypeNo === 3) {
        if (['1', '2', '3', '4'].indexOf(lastNumber) === -1) return false
        return true
    }
    // 장애인 = 4
    // 주민번호 1,2,3,4
    if (applyTypeNo === 4) {
        if (['1', '2', '3', '4'].indexOf(lastNumber) === -1) return false
        return true
    }
    // 다문화 = 5
    // 주민번호 1,2,3,4,5,6,7,8
    if (applyTypeNo === 5) {
        if (['1', '2', '3', '4', '5', '6', '7', '8'].indexOf(lastNumber) === -1)
            return false
        return true
    }
    // 일반 = 6
    if (applyTypeNo === 6) {
        return true
    }
}
