import dayjs from 'dayjs'

export const nameValidation = (name: string) => {
    if (name.length > 2 && name.length < 20) {
        return true
    }
    return false
}

export const dateValidation = (date: string) => {
    const dateRegEx = new RegExp(/^\d{4}.\d{2}.\d{2}$/)
    if (dateRegEx.test(date)) {
        return true
    }
    return false
}

export const dateCheck = (date: string, name: string) => {
    if (!dateValidation(date) || name.split('_')[1] !== 'date') return
    // const year = dayjs().year()
    const year = +date.split('.')[0]
    const nowMonth = +date.split('.')[1]
    const nowDay = +date.split('.')[2]
    const minDate = dayjs(date).diff(year, 'year')
    const inputDate = dayjs(date).valueOf()
    const today = dayjs().valueOf()
    const monthDay = getDaysInMonth(year, nowMonth)

    if (!(nowMonth >= 1 && nowMonth <= 12)) {
        // alert('월은 1~12 사이의 숫자만 입력할 수 있습니다.')
        // return true
        return `월은 1~12 사이의 숫자만 입력할 수 있습니다.`
    }
    if (!(nowDay >= 1 && nowDay <= monthDay)) {
        // alert(`일은 1~${monthDay} 사이의 숫자만 입력할 수 있습니다.`)
        // return true
        return `일은 1~${monthDay} 사이의 숫자만 입력할 수 있습니다.`
    }
    if (inputDate > today) {
        // alert('오늘 이후의 날짜는 입력할 수 없습니다.')
        // return true
        return `오늘 이후의 날짜는 입력할 수 없습니다.`
    }
    if (minDate < -69) {
        // alert('1900.01.01 이전의 날짜는 입력할 수 없습니다.')
        // return true
        return `1900.01.01 이전의 날짜는 입력할 수 없습니다.`
    }
    // return false
    return ``
}

// 윤년 구하기
export const isLeapYear = (year: number) => {
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
        return true
    }
    return false
}

// 월 별 일자 구하기
export const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate()
}

// 텍스트만 입력했는지, 길이가 20자 이내인지
export const textCheck = (value: string) => {
    const textRegEx = new RegExp(/^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/)

    if (value.length > 0 && !textRegEx.test(value)) {
        // alert('한글과 영문만 입력할 수 있습니다.')
        return true
    }

    return false
}

// 핸드폰 번호 정규식
export const phoneCheck = (value: string) => {
    const emptyPhoneRegEx = new RegExp(/^\d{3}-\d{3,4}-\d{4}$/)
    const phoneRegEx = new RegExp(
        /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    )
    if (
        value.length > 0 &&
        emptyPhoneRegEx.test(value) &&
        !phoneRegEx.test(value)
    ) {
        // console.log('true', value)
        // alert('핸드폰 번호 형식에 맞게 입력해주세요.')
        return true
    }

    return false
}

export const firstNameLengthCheck = (value: string) => {
    const year = value.split('').splice(0, 2).join('')
    const month = value.split('').splice(2, 2).join('')
    const day = value.split('').splice(4, 2).join('')
    if (month.length > 1 && !(+month >= 1 && +month <= 12)) {
        // alert('01 ~ 12 사이의 숫자만 입력할 수 있습니다.')
        return true
    }
    if (day.length > 1 && !(+day >= 1 && +day <= 31)) {
        // alert('01 ~ 31 사이의 숫자만 입력할 수 있습니다.')
        return true
    }
    return false
}

export const lengthValidationCheck = (value: string, length: number) => {
    if (value) {
        return true
    } else {
        return false
    }
}
