import styled from '@emotion/styled'
import React from 'react'
import colors from '../../utils/colors'

interface Props {
    num?: number
    text?: string
    check?: boolean
    background?: string
    color?: string
    numColor?: string
}

const ProgressItem: React.FC<Props> = ({
    num,
    text,
    check,
    background,
    color,
    numColor,
}) => {
    return (
        <Layout>
            <Circle background={background} color={numColor}>
                {check ? <CheckIcon /> : num}
            </Circle>
            <Text color={color}>{text}</Text>
        </Layout>
    )
}

export default React.memo(ProgressItem)

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Circle = styled.div<{
    check?: boolean
    background?: string
    color?: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-bottom: 8px;

    // background: ${({ check }) =>
        check ? colors.confirmGreen : colors.mainColor};
    background: ${({ background }) => background};
    border-radius: 50%;

    line-height: 50px;
    text-align: center;
    font-size: 24px;
    color: ${({ color }) => color};
`

const Text = styled.div<{ color?: string }>`
    font-weight: bold;
    font-size: 18px;
    color: ${({ color }) => color};
`

const CheckIcon = styled.i`
    display: inline-block;
    width: 37.5px;
    height: 37.5px;
    background-image: url('/icon/Check.svg');
`
