import React from 'react';
import reactDom from 'react-dom';

interface Props {
  children: React.ReactNode;
}
const ModalPortal: React.FC<Props> = ({ children }) => {
  const el = document.getElementById('modal');
  return reactDom.createPortal(children, el!);
};

export default ModalPortal;
