import styled from '@emotion/styled'
import React from 'react'

interface Props {
    width: number
    height: number
}
const RoundCheckIcon: React.FC<Props> = ({ width, height }) => {
    return <Icon width={width} height={height} />
}

export default RoundCheckIcon

const Icon = styled.i<{ width: number; height: number }>`
    display: inline-block;
    width: ${({ width }) => (width ? width : 22)}px;
    height: ${({ height }) => (height ? height : 22)}px;
    background-image: url('/icon/ConfirmIcon.svg');
`
