import React from 'react'
import styled from '@emotion/styled'
import colors from '../../utils/colors'

interface Props {
    // width: 'maximun' | 'large' | 'middle' | 'small' | 'minimum'
    width?: number
    height?: number
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    max?: number
    min?: number
    type: 'number' | 'text' | 'password' | 'email'
    name: string
    value?: string | number
    disabled?: boolean
    required?: boolean
    placeholder?: string
    background?: string
    line?: string
    disabledBorder?: string
}
const Input: React.FC<Props> = ({
    width,
    height,
    onChange,
    max,
    min,
    type,
    name,
    value,
    disabled,
    required,
    placeholder,
    background,
    line,
    disabledBorder = `2px solid ${colors.borderGray}`,
}) => {
    return (
        <InputEelements
            type={type}
            name={name}
            width={width}
            value={value}
            height={height}
            onChange={onChange}
            maxLength={max}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            background={background}
            line={line}
            minLength={min}
            autoComplete="off"
            disabledBorder={disabledBorder}
        />
    )
}

export default React.memo(Input)

const InputEelements = styled.input<{
    width?: number
    height?: number
    disabled?: boolean
    background?: string
    line?: string
    disabledBorder?: string
}>`
    width: ${({ width }) => (width ? width : 130)}px;
    height: ${({ height }) => (height ? height : 40)}px;

    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
    border: ${({ line }) => (line ? line : `2px solid ${colors.borderGray}`)};
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;
    color: #0a0e2e;
    outline: none;
    box-sizing: border-box;
    &::placeholder {
        color: ${colors.borderGray};
    }
    &:disabled {
        background: ${({ background }) =>
            background ? background : colors.disabledColor};
        border: ${({ disabledBorder }) =>
            disabledBorder ? disabledBorder : `2px solid ${colors.borderGray}`};
        cursor: not-allowed;
    }
    &:focus {
        border: 2px solid ${colors.subColor};
    }
`
