import { RecruitDetail } from './../../types/Recruit'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ApplyType {
    apply_type_no: number
    apply_type_name: string
    isVisible: boolean
}
interface GlobalBg {
    src: string
    bgColor: string
}
interface Initial {
    applyTypes: ApplyType[]
    end: boolean
    globalBg: GlobalBg
    recruitDetail?: RecruitDetail
}
const initialState: Initial = {
    applyTypes: [],
    end: false,
    globalBg: {
        src: '',
        bgColor: '',
    },
    recruitDetail: undefined,
}
export const typeSlice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        setTypes: (state, action: PayloadAction<ApplyType[]>) => {
            state.applyTypes = action.payload
        },
        setEnd: (state, action: PayloadAction<boolean>) => {
            state.end = action.payload
        },
        setGlobalBg: (state, action: PayloadAction<GlobalBg>) => {
            state.globalBg = action.payload
        },
        setRecruitDetailInfo: (state, action: PayloadAction<RecruitDetail>) => {
            state.recruitDetail = action.payload
        },
    },
})

export const { setTypes, setEnd, setGlobalBg, setRecruitDetailInfo } =
    typeSlice.actions
export default typeSlice.reducer
