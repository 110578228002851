// 1	미취업청년
// 2	경력단절녀
// 3	차상위계층
// 4	장애인
// 5	다문화

export interface Question {
    question: string
    choose: { label: string; to: string }[]
    result?: boolean
}

const questions: { [index: string]: { [index: string]: Question } } = {
    g: {
        start: {
            question: '채용일 기준 만 19세 이상이십니까?',
            choose: [
                { label: '그렇습니다', to: 'p1' },
                { label: '아닙니다', to: 'g-fail' },
            ],
        },
        // 전역 (시작점) 실패 메세지
        'g-fail': {
            question:
                '죄송합니다.\n나이 기준이 적합하지 않아 지원 하실 수 없습니다',
            choose: [],
            result: false,
        },
        // 페이지별 공통 실패 메세지
        'p-fail': {
            question: '기준이 적합하지 않습니다',
            choose: [],
            result: false,
        },
    },
    // 미취업청년
    '1': {
        p1: {
            question: '귀하의 성별은 어떻게 되십니까?',
            choose: [
                { label: '여성입니다', to: 'p2-1' },
                { label: '남성입니다', to: 'p2-2' },
            ],
        },
        'p2-1': {
            question: '채용일 기준 만 나이 34세 이하 인가요?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p2-2': {
            question: '군필 이십니까?',
            choose: [
                { label: '그렇습니다', to: 'p3' },
                { label: '아닙니다', to: 'p4' },
            ],
        },
        p3: {
            question: '채용일 기준 군필자 만 나이 기준에 부합하십니까?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        p4: {
            question: '채용일 기준 만 나이 34세 이하 인가요?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '미취업 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
    },
    // 경력단절녀
    '2': {
        p1: {
            question: '귀하의 성별은 어떻게 되십니까?',
            choose: [
                { label: '여성입니다', to: 'p2' },
                { label: '남성입니다', to: 'p-fail' },
            ],
        },
        p2: {
            question: '공고일 기준 미취업자이십니까?',
            choose: [
                { label: '그렇습니다', to: 'p3' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        p3: {
            question: '현재 기혼이시거나 부양 가족이 있으십니까?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '경력단절여성 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
    },
    // 차상위계층
    '3': {
        p1: {
            question: '차상위계층 증명서를 보유하시거나, 발급이 가능하십니까?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '차상위계층 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
    },
    // 장애인
    '4': {
        start: {
            question: '장애인 증명서를 보유하시거나, 발급이 가능하십니까?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '장애인 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
        'p-fail': {
            question: '기준이 적합하지 않아 지원 하실 수 없습니다',
            choose: [],
            result: false,
        },
    },
    // 다문화
    '5': {
        p1: {
            question:
                '가족 구성원 중 외국인이 있습니까?\n(본인, 배우자 및 부모 포함)',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '다문화 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
    },
    // 일반
    '6': {
        p1: {
            question: '현재 미취업 상태입니까?',
            choose: [
                { label: '그렇습니다', to: 'p-success' },
                { label: '아닙니다', to: 'p-fail' },
            ],
        },
        'p-success': {
            question: '일반 전형이 적합합니다\n공고에 지원하세요',
            choose: [],
            result: true,
        },
    },
}

export const selfCheckScript = (
    applyTypeNo: number,
    page: string,
): Question => {
    // 장애인은 공통 질문 제외
    if (applyTypeNo !== 4) {
        const selfScript = {
            ...questions.g,
            ...questions[applyTypeNo],
        }
        return selfScript[page]
    } else {
        const selfScript = {
            ...questions[applyTypeNo],
        }
        return selfScript[page]
    }
}
