import axios, { AxiosInstance, AxiosResponse } from 'axios'

const customAxios: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
        Authorization: `${sessionStorage.getItem('token_type')} ${sessionStorage.getItem(
            'access_token',
        )}`,
    },
})

const fileUploadAxios: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${sessionStorage.getItem('token_type')} ${sessionStorage.getItem(
            'access_token',
        )}`,
    },
})

export const get = async <T>(url: string, options?: any): Promise<AxiosResponse<T>> => {
    try {
        const newOptions = {
            ...options,
            ...{
                headers: {
                    Authorization: `${sessionStorage.getItem(
                        'token_type',
                    )} ${sessionStorage.getItem('access_token')}`,
                },
            },
        }
        const response = await customAxios.get(url, newOptions)
        return response
    } catch (e) {
        throw e
    }
}

export const post = async <T>(
    url: string,
    data?: any,
    options?: any,
): Promise<AxiosResponse<T>> => {
    try {
        console.log('axios call start')
        const newOptions = {
            ...options,
            ...{
                headers: {
                    Authorization: `${sessionStorage.getItem(
                        'token_type',
                    )} ${sessionStorage.getItem('access_token')}`,
                },
            },
        }
        const response = await customAxios.post(url, data, newOptions)
        console.log('axios response', response)
        return response
    } catch (e) {
        throw e
    }
}

export const put = async <T>(url: string, data: any): Promise<AxiosResponse<T>> => {
    try {
        const response = await customAxios.put(url, data)
        return response
    } catch (e) {
        throw e
    }
}

export const patch = async <T>(
    url: string,
    data: any,
    options?: any,
): Promise<AxiosResponse<T>> => {
    try {
        const newOptions = {
            ...options,
            ...{
                headers: {
                    Authorization: `${sessionStorage.getItem(
                        'token_type',
                    )} ${sessionStorage.getItem('access_token')}`,
                },
            },
        }
        const response = await customAxios.patch(url, data, newOptions)
        return response
    } catch (e) {
        throw e
    }
}

export const fileUploadPost = async <T>(
    url: string,
    data: any,
    options?: any,
): Promise<AxiosResponse<T>> => {
    try {
        console.log('axios call start')
        const response = await fileUploadAxios.post(url, data, options)
        console.log('axios response', response)

        return response
    } catch (e) {
        console.log('e post =>', e)
        throw e
    }
}

export const fileUploadPut = async <T>(
    url: string,
    data: any,
    options?: any,
): Promise<AxiosResponse<T>> => {
    try {
        const response = await axios.put(url, data, options)
        console.log('file upload aioxs put response', response)
        return response
    } catch {
        throw new Error('File Upload Axios Error')
    }
}
