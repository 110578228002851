import React, { useEffect, useState } from 'react'
import PrevWithNextArea from './PrevWithNextArea'
import { useParams } from 'react-router-dom'
import Input from '../elements/Input'
import styled from '@emotion/styled'
import RequireText from '../elements/RequireText'
import { fileUploadPut, get, post } from '../../api/customAxios'
import { AxiosError } from 'axios'
import { ErrorMessage } from '../../types/Error'
import Indicator from '../Indicator'
import colors from '../../utils/colors'

interface FilePresign {
    file_no: number
    upload_url: string
}
interface FileUpload {
    appl_file_type_no: number
    appl_file_type_name: string
    file_no: number
    file_name: string
    is_require: boolean
    file_fit_status: string
    upload_url: string
    file?: File
    // color?: string
    status: number
}
interface Props {
    onNext: () => void
    onPrev: () => void
}
const FileUploadForm: React.FC<Props> = ({ onNext, onPrev }) => {
    const token = sessionStorage.getItem('access_token')
    const tokenType = sessionStorage.getItem('token_type')
    const [success, setSuccess] = useState(false)
    const params = useParams()
    const [files, setFiles] = useState<File[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const [fileUploads, setFileUploads] = useState<FileUpload[]>([
        {
            appl_file_type_no: 1,
            appl_file_type_name: '',
            file_no: 1,
            file_name: '',
            is_require: false,
            file_fit_status: '',
            upload_url: '',
            file: undefined,
            status: 0,
        },
    ])

    const handleFileListGetApi = async (param: any) => {
        const stime = new Date().getTime()
        try {
            // setIsLoading(true)

            const res = await get<FileUpload[]>(
                `${process.env.REACT_APP_PREFIX}/applies/files`,
                param,
            )

            const temp = res.data.map((item) =>
                item.file_fit_status === '부적합'
                    ? {
                          ...item,
                          status: 2,
                      }
                    : {
                          ...item,
                          status: 0,
                      },
            )
            setFileUploads(temp)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        } finally {
            // setTimeout(() => {
            //     setIsLoading(false)
            // }, Math.max(0, 2000 - new Date().getTime() - stime))
        }
    }

    // 임시 인증 경로 얻기
    const handlePresignUrlApi = async (fileName: string) => {
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')
        try {
            const res = await get<FilePresign>(
                `${process.env.REACT_APP_PREFIX}/applies/files/pre-sign`,
                {
                    params: {
                        file_name: fileName,
                    },
                    headers: {
                        Authorization: `${tokenType} ${token}`,
                    },
                },
            )
            return res.data
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }

    // s3에 파일 업로드
    const fileUploadToS3 = async (url: string, file: File) => {
        const res = await fileUploadPut<FilePresign>(url, file, {
            headers: {
                'Content-Type': file.type,
            },
        })
        return res
    }

    const handleFileUpload = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: number,
    ): void => {
        const { name, files } = e.target
        const fileName = files !== null ? files[0].name : ''
        const fileArr = Array.from(files !== null ? files : [])

        for (const x of fileArr) {
            // /\.(jpe?g|png|gif|pdf)$/g.test(x.name)
            if (/\.(jpe?g|png|pdf)$/g.test(x.name.toLowerCase())) {
                setFiles((prev) => [...prev, ...fileArr])
                setFileUploads((prev) =>
                    prev.map((item) => {
                        if (item.appl_file_type_no === id) {
                            return {
                                ...item,
                                [name]: fileName.toLowerCase(),
                                status: 0,
                                // color: '#CCCCCC',
                                file:
                                    files && files?.length > 0
                                        ? files[0]
                                        : undefined,
                            }
                        }
                        return item
                    }),
                )
            }
        }
    }

    const handleSubmission = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                await post(`${process.env.REACT_APP_PREFIX}/applies/submission`)
                resolve(true)
            } catch (e) {
                const { response } = e as unknown as AxiosError
                alert((response?.data as ErrorMessage)?.detail)
            }
            resolve(false)
        })
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }
    const handleSuccess = async () => {
        // 처리중엔 막음
        if (isLoading) return
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')

        try {
            // 파일을 formData에 넣을때 key를 apply_file_type_no로 넣어야함
            // 첨부된 파일이 있다면 업로드 => 파일 확정 처리 순으로 진행
            setIsLoading(true)

            // 파일을 업로드 처리
            const hasFiles = fileUploads.filter((r) => r.file)
            for (const fileObj of hasFiles) {
                const presign = await handlePresignUrlApi(fileObj.file!.name)
                fileObj.file_no = presign!.file_no
                await fileUploadToS3(presign!.upload_url, fileObj.file!)
            }

            const fileApplies = hasFiles.map((row) => {
                return {
                    appl_file_type_no: row.appl_file_type_no,
                    file_no: row.file_no,
                    file_name: row.file?.name.toLowerCase(),
                }
            })

            const res = await post(
                `${process.env.REACT_APP_PREFIX}/applies/files`,
                fileApplies,
                {
                    headers: {
                        Authorization: `${tokenType} ${token}`,
                    },
                },
            )

            if (res) {
                const subResult = await handleSubmission()
                if (!subResult) return
            }
            setIsLoading(false)
            setTimeout(() => {
                onNext()
            }, 500)
        } catch {
            // console.log('파일 업로드에 실패했습니다.')
            alert(`파일 업로드에 실패 하였습니다.
참조된 파일을 변경하거나 지우시는 경우 발생할 수 있습니다.
새로 고침 후 다시 시도해주시기 바랍니다.`)
            throw new Error('파일 업로드에 실패했습니다.')
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const token = sessionStorage.getItem('access_token')
        const tokenType = sessionStorage.getItem('token_type')
        handleFileListGetApi({
            headers: {
                Authorization: `${tokenType} ${token}`,
            },
        })
    }, [tokenType, token])

    useEffect(() => {
        let hasEmptyFile = false
        fileUploads
            .filter((item) => item.is_require)
            .forEach((v) => {
                if (
                    v.file_name !== '' &&
                    v.file_name !== null &&
                    v.status === 0
                ) {
                    // checkCnt++
                } else {
                    hasEmptyFile = true
                }
            })

        if (!hasEmptyFile) {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }, [fileUploads])

    return (
        <Layout onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="require_head">
                <RequireText title="* 표시가 된 칸은 필수로 입력해야 합니다." />
            </div>
            <div className="upload_form">
                {fileUploads.map((item) => (
                    <div
                        className="column"
                        key={item.appl_file_type_no}
                        style={{
                            marginTop:
                                !item.is_require &&
                                item ===
                                    fileUploads.find((row) => !row.is_require)
                                    ? 100
                                    : 0,
                        }}
                    >
                        <div className="title end">
                            <div className="word_break">
                                {item.appl_file_type_name}
                            </div>
                            {item.is_require && (
                                <div className={`require_text`}>
                                    <RequireText title="*" />
                                </div>
                            )}
                        </div>
                        <div
                            className="contents"
                            style={{ position: 'relative' }}
                        >
                            {!item.is_require && (
                                <div className="contents_guide_text">
                                    해당되는 경우에만 등록해주세요
                                </div>
                            )}

                            <InputBox>
                                <Input
                                    width={368}
                                    onChange={() => {}}
                                    type={'text'}
                                    name={'file_upload_name'}
                                    value={item.file_name || ''}
                                    disabled={true}
                                    disabledBorder={`2px solid ${
                                        item.status === 2
                                            ? colors.warningColor
                                            : colors.borderGray
                                    }`}
                                    background="linear-gradient(180deg, #FEFEFE 0%, #FCFCFC 100%)"
                                    placeholder="선택된 파일 없음"
                                />

                                {item.status === 2 && <WarningIcon />}
                            </InputBox>
                            {item.status === 2 && (
                                <WarningText>
                                    변경이 필요한 파일입니다.
                                </WarningText>
                            )}
                        </div>
                        <div>
                            <FileBtn
                                htmlFor={item.appl_file_type_no + ''}
                                width={70}
                                height={30}
                            >
                                파일선택
                            </FileBtn>
                            <FileInput
                                // multiple
                                onChange={(e) =>
                                    handleFileUpload(e, item.appl_file_type_no)
                                }
                                type="file"
                                name="file_name"
                                accept=".jpeg, .jpg, .png, .pdf"
                                id={item.appl_file_type_no + ''}
                            />
                        </div>
                    </div>
                ))}
            </div>

            <Indicator isLoading={isLoading} top={0} />

            <div className="foot">
                <PrevWithNextArea
                    onNext={handleSuccess}
                    id={params.id}
                    onPrev={onPrev}
                    success={success}
                />
            </div>
        </Layout>
    )
}

export default FileUploadForm

const Layout = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .require_head {
        width: 500px;
        padding: 0 50px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
    }
    .upload_form {
        margin-bottom: 80px;
    }
    .column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        // &:last-of-type {
        //     margin-top: 100px;
        // }
    }
    .title {
        position: relative;
        display: flex;

        width: 130px;
        font-weight: 600;
        font-size: 16px;
        margin-right: 27px;
        .word_break {
            word-break: keep-all;
            text-align: right;
        }
    }
    .start {
        justify-content: flex-start;
    }
    .end {
        justify-content: flex-end;
    }

    .require_text {
        position: absolute;
        right: -15px;
        top: -3px;
    }
    .contents {
        margin-right: 16px;
        .contents_guide_text {
            position: absolute;
            top: -22px;
            left: 2px;
            font-size: 14px;
            line-height: 17px;
            color: ${colors.textGray};
        }
    }
    .foot {
        margin: 98px 0 60px 0;
    }
`

const FileBtn = styled.label<{ width?: number; height?: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => (width ? width : 100)}px;
    height: ${({ height }) => (height ? height : 40)}px;
    background: linear-gradient(180deg, #2278f1 0%, #1465d7 100%);
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;
`
const FileInput = styled.input`
    display: none;
`

const InputBox = styled.div`
    display: flex;
    align-items: center;
`
const WarningIcon = styled.i`
    position: absolute;
    right: 10px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/icon/WarningIcon.svg');
`

const WarningText = styled.div`
    position: absolute;
    bottom: -18px;
    left: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.warningColor};
`
