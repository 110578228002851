import styled from '@emotion/styled'
import React from 'react'

interface Props {
    src: string
}
const Card: React.FC<Props> = ({ src }) => {
    return <Layout src={src}></Layout>
}

export default React.memo(Card)

const Layout = styled.div<{ src: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 300px;
    height: 375px;
    background: #f2f7ff;
    background-image: url(http://${({ src }) => src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    border-radius: 4px;
    // margin: 10px;
    box-sizing: border-box;
    img {
        width: 100%;
        height: 100%;
        background-size: contain;
    }
`
