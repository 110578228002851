import styled from '@emotion/styled'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import TypeButton from '../../elements/TypeButton'
import SelfAppraisal from '../SelfAppraisal'
import { Question, selfCheckScript } from './selfCheckScript'
import ModalStyled from './ModalStyled'

interface Props {
    setShowModal: Dispatch<SetStateAction<boolean>>
    applyTypeNo?: number
}
const SelfCheck: React.FC<Props> = ({ setShowModal, applyTypeNo = 1 }) => {
    const [page, setPage] = useState<string>('start')
    const [question, setQuestion] = useState<Question>({
        question: '',
        choose: [],
    })
    useEffect(() => {
        const sc = selfCheckScript(applyTypeNo, page)
        setQuestion(sc)
    }, [page])

    const getPositionName = (applyTypeNo: number) => {
        if (applyTypeNo === 1) return '미취업청년'
        if (applyTypeNo === 2) return '경력단절여성'
        if (applyTypeNo === 3) return '차상위계층'
        if (applyTypeNo === 4) return '장애인'
        if (applyTypeNo === 5) return '다문화'
        if (applyTypeNo === 6) return '일반'
        return ''
    }

    return (
        <ModalStyled>
            <SelfAppraisal
                subject={getPositionName(applyTypeNo) + '-자가진단'}
                setShowModal={setShowModal}
            >
                <Body>
                    {question.result === false && <FailIcon />}
                    {question.result === true && <SuccessIcon />}

                    <Pre>{question.question}</Pre>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: 400,
                        }}
                    >
                        {question.choose.map((q) => (
                            <TypeButton
                                key={q.to}
                                type={'confirm'}
                                onClick={() => {
                                    setPage(q.to)
                                }}
                                icon={false}
                                sort={true}
                                fSize={18}
                            >
                                {q.label}
                            </TypeButton>
                        ))}
                    </div>
                </Body>
                <div>
                    <TypeButton
                        type={'cancel'}
                        onClick={() => {
                            setShowModal(false)
                        }}
                        icon={false}
                        sort={true}
                        fSize={18}
                        size={100}
                    >
                        {question.result !== undefined ? '닫기' : '그만하기'}
                    </TypeButton>
                </div>
            </SelfAppraisal>
        </ModalStyled>
    )
}

export default SelfCheck

const Body = styled.div`
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Pre = styled.pre`
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
`

const FailIcon = styled.i`
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
    background-image: url('/icon/SelfCheckFail.svg');
`
const SuccessIcon = styled.i`
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
    background-image: url('/icon/SelfCheckSuccess.svg');
`
