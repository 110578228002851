import React, { useEffect, useState } from 'react'
import RequireText from './RequireText'
import styled from '@emotion/styled'
import colors from '../../utils/colors'

interface Props {
    title?: string
    require?: boolean
    style?: React.CSSProperties
    maxLength?: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    name: string
}
const TextArea: React.FC<Props> = ({
    title,
    require,
    style,
    maxLength = 0,
    value,
    onChange,
    name,
}) => {
    const [textLength, setTextLength] = useState('')
    // const handleTextLengthCheck = (param: string) => {}
    useEffect(() => {
        setTextLength(value)
    }, [value])
    return (
        <Layout style={style}>
            <div className="top">
                <div>
                    <h3>{title}</h3>
                    {require && <RequireText title={'*'} />}
                    <div className="guide_text">
                        {maxLength}자 내로 작성해주세요
                    </div>
                </div>
                <div className="length_text">
                    ({textLength.length}/{maxLength})
                </div>
            </div>
            <div className="bottom">
                <textarea
                    value={value}
                    onChange={onChange}
                    maxLength={+maxLength}
                    name={name}
                />
            </div>
        </Layout>
    )
}

export default TextArea

const Layout = styled.div`
    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        div {
            display: flex;
            align-items: center;
        }
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 9px;
        margin-right: 2px;
    }

    .guide_text {
        display: flex;
        align-items: center;
        margin-left: 20px;

        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${colors.textGray};
    }
    .length_text {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        color: ${colors.textGray};
    }
    .bottom {
        display: flex;

        textarea {
            width: 100%;
            height: 250px;
            padding: 14px;
            border: 2px solid ${colors.borderGray};
            border-radius: 4px;
            background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
            font-family: 'Pretendard';
            font-size: 15px;
            line-height: 19px;
            font-weight: 400;
            color: ${colors.textBoldColor};
            outline: none;
            resize: none;
            &:focus {
                border: 2px solid ${colors.subColor};
            }
        }
    }
`
