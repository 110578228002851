import styled from '@emotion/styled'
import React from 'react'
import colors from '../../../utils/colors'

interface Props {
    children?: React.ReactNode
}
const ModalStyled: React.FC<Props> = ({ children }) => {
    return <Layout>{children}</Layout>
}

export default ModalStyled
const Layout = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 620px;
    height: 467px;
    transform: translate(-50%, -50%);
    border: 2px solid ${colors.gray};
    box-shadow: 0px 0px 8px ${colors.borderGray};
    border-radius: 10px;
    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
`
