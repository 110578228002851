import styled from '@emotion/styled'
import React from 'react'
import TypeButton from '../elements/TypeButton'
import { useDelayEffect } from '../../hooks/useDelay'

interface Props {
    success: boolean
    onPrev: () => void
    onNext: () => void
    id?: string
    // isDelay?: boolean
}
const PrevWithNextArea: React.FC<Props> = ({
    success,
    onPrev,
    onNext,
    id,
    // isDelay,
}) => {
    const lastCheck = id !== undefined && +id === 5 ? '지원하기' : '다음'
    const isDelay = useDelayEffect(1000)
    return (
        <Layout>
            <div className="button_form">
                <TypeButton
                    type={'cancel'}
                    icon={false}
                    sort={true}
                    disabled={isDelay}
                    onClick={onPrev}
                >
                    이전
                </TypeButton>
                <TypeButton
                    type={'confirm'}
                    icon={false}
                    sort={true}
                    disabled={!success}
                    onClick={onNext}
                >
                    {lastCheck}
                </TypeButton>
            </div>
        </Layout>
    )
}

export default PrevWithNextArea

const Layout = styled.div`
    display: flex;
    justify-content: center;
    .button_form {
        display: flex;
        justify-content: space-between;
        width: 330px;
    }
`
