import styled from '@emotion/styled'
import React from 'react'
import SingleCheckIcon from '../icon/SingleCheckIcon'
import colors from '../../utils/colors'

interface Props {
    title: string
    id: number
    typeNo: number
    onClick: (id: number) => void
    onHelp: (id: number) => void
    disabled?: boolean
    isSelected?: boolean
}
const SelectionCheck: React.FC<Props> = ({
    title,
    id,
    typeNo,
    onClick,
    onHelp,
    disabled,
    isSelected,
}) => {
    return (
        // 제출완료 상태 일시 disabled 해줘야함
        <Layout bg={disabled}>
            <CheckForm
                onClick={() => !disabled && onClick(id)}
                disabled={!disabled}
            >
                {typeNo === id ? (
                    <SingleCheckIcon width={18} height={18} />
                ) : null}
            </CheckForm>

            <p>{title}</p>
            <i className="icon_question" onClick={() => onHelp(id)} />
        </Layout>
    )
}

export default SelectionCheck

const Layout = styled.div<{ bg?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    height: 40px;
    padding: 0 17px;
    border-radius: 4px;
    background: ${({ bg }) =>
        bg
            ? colors.disabledColor
            : 'linear-gradient(180deg, #2278f1 0%, #1465d7 100%)'};
    box-sizing: border-box;
    .icon_question {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('/icon/Question.svg');
        cursor: pointer;
    }
    p {
        font-weight: 600;
        font-size: 16px;
        color: #fefefe;
    }
`
const CheckForm = styled.div<{ disabled?: boolean }>`
    width: 18px;
    height: 18px;
    border: 2px solid #f5f5f5;
    border-radius: 2px;
    background: #f5f5f5;
    cursor: ${({ disabled }) => (disabled ? 'pointer' : 'not-allowed')};
`
