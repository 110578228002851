import styled from '@emotion/styled'
import React, { Dispatch, SetStateAction } from 'react'
import TypeButton from '../../elements/TypeButton'
import SelfAppraisal from '../SelfAppraisal'
import colors from '../../../utils/colors'
import ModalStyled from './ModalStyled'

interface Props {
    setShowModal: Dispatch<SetStateAction<boolean>>
    setShowSelfCheck: () => void
}
const MultiCultural: React.FC<Props> = ({ setShowModal, setShowSelfCheck }) => {
    return (
        <ModalStyled>
            <SelfAppraisal subject={'다문화 가정'} setShowModal={setShowModal}>
                ● 본 사업은 정부기관과의 협력을 통한 일자리 창출 사업의
                일환으로써, 국적법에 의거한 다문화 가족의 소속 구성원에게 양질의
                일자리를 제공하는 것을 목적으로 합니다.
                <br />
                <br />
                지원 자격은 다음과 같습니다.
                <Ul>
                    <li>채용일 기준 만 60세 이하</li>
                    <li>국적법 상 다문화 가족으로 이루어진 가족 내 구성원</li>
                </Ul>
                <br />
                <Ul>
                    <li>
                        지원자가 외국 국적인 경우 외국인등록증 및
                        가족관계증명서를 필수적으로 제출하여야 합니다.
                    </li>
                    <li>
                        가족 구성원이 외국 국적인 경우 가족관계증명서를
                        필수적으로 제출하여야 합니다.
                    </li>
                    <li>
                        입사 지원이 정상적으로 접수되면 SMS·이메일·알림톡을 통한
                        접수 알림이 발송됩니다.
                    </li>
                    <li>
                        입사 지원은 마감 시점 이전까지 제출 버튼을 누르셔야 정상
                        접수됩니다.
                    </li>
                    <li>
                        지원 마감일의 경우 지원자가 과다하게 몰려, 제출이
                        정상적으로 처리되지 않을 수 있습니다.
                        <br />
                        이에 따라 충분한 시간적 여유를 가지고 지원해 주시기
                        바라며, 그렇지 않음으로 인해 발생하는 불이익에 대해서는
                        당사는 책임을 지지 않습니다.
                    </li>
                </Ul>
                <br />
                <span style={{ color: colors.mainColor }}>
                    *더 궁금한 사항은 담당부서(help@galactic-monitoring.co.kr)를
                    통해 문의하여 주시기 바랍니다.
                </span>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%',
                        bottom: 24,
                        left: 0,
                    }}
                >
                    <TypeButton
                        type={'confirm'}
                        onClick={() => {
                            setShowSelfCheck()
                        }}
                        icon={false}
                        sort={true}
                        size={100}
                        fSize={18}
                    >
                        자가진단
                    </TypeButton>
                    <div style={{ width: 30 }}></div>
                    <TypeButton
                        type={'cancel'}
                        onClick={() => {
                            setShowModal(false)
                        }}
                        icon={false}
                        sort={true}
                        size={100}
                        fSize={18}
                    >
                        닫기
                    </TypeButton>
                </div>
            </SelfAppraisal>
        </ModalStyled>
    )
}

export default MultiCultural

const Ul = styled.ul`
    list-style: disc;
    li {
        list-style: disc;
        margin-left: 20px;
    }
`
