import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './ pages/Main'
import Recruit from './ pages/Recruit'
import OperInfo from './ pages/OperInfo'
import Apply from './ pages/Apply'
import { get } from './api/customAxios'
import { RecruitInfo } from './types/Recruit'
import { AxiosError } from 'axios'
import { ErrorMessage } from './types/Error'

// const check = process.env.REACT_APP_STATUS === 'product' ? true : false
// if (check) console.log = () => {}

function App() {
    const [recruits, setRecruits] = useState<RecruitInfo[]>([])
    const handleRecruit = async () => {
        try {
            const res = await get<RecruitInfo[]>(
                `${process.env.REACT_APP_PREFIX}/recruits`,
            )
            setRecruits(res.data)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }
    useEffect(() => {
        process.env.REACT_APP_STATUS === 'dev' && console.log('dev')
        if (sessionStorage.getItem('end') !== 'false') {
            sessionStorage.setItem('end', 'true')
        }
    }, [])
    useEffect(() => {
        handleRecruit()
    }, [])

    return (
        <Routes>
            <Route path="/" element={<Main recruits={recruits} />} />
            <Route path="/recruit/:id" element={<Recruit />} />
            <Route path="/recruit/:id/apply/:id" element={<Apply />} />
            <Route path="/operinfo" element={<OperInfo />} />
        </Routes>
    )
}

export default App
