import React, { useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import ProgressBar from '../components/elements/ProgressBar'
import styled from '@emotion/styled'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AgreeForm from '../components/recruitment/AgreeForm'
import RequireUserInfo from '../components/recruitment/RequireUserInfo'
import UserSelection from '../components/recruitment/UserSelection'
import SelfIntroduction from '../components/recruitment/SelfIntroduction'
import FileUploadForm from '../components/recruitment/FileUploadForm'
import { Agree } from '../types/Agree'
import RecruitSuccess from '../components/recruitment/RecruitSuccess'

interface Props {}
const Apply: React.FC<Props> = () => {
    const token = sessionStorage.getItem('access_token')
    const current = sessionStorage.getItem('currentPage')
    const recruitNo = sessionStorage.getItem('recruit_no')
    const end = sessionStorage.getItem('end')
    const [bannerConfig, setBannerConfig] = useState({
        imgUrl: sessionStorage.getItem('imgUrl'),
        bgColor: sessionStorage.getItem('bgColor'),
    })
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const [agree, setAgree] = useState<Agree>({
        personal_information_yn: false,
        service_terms_yn: false,
        marketing_yn: false,
    })
    const routeParamCheck = (param: string) => {
        if (param === null || param === undefined || param === '0')
            return navigate('/')
    }
    const handleNextRoute = () => {
        const prefix = location.pathname.slice(0, -2)
        if (params.id) {
            // if (!sessionStorage.getItem('agree')) {
            sessionStorage.setItem('agree', JSON.stringify(agree))
            // }
            navigate(`${prefix}/${+params.id + 1}`)
        }
    }

    const handlePrevRoute = () => {
        const prefix = location.pathname.slice(0, -2)
        if (params.id) {
            navigate(`${prefix}/${+params.id - 1}`)
        }
    }
    const paramCheck = params.id !== undefined ? +params.id : 0

    useEffect(() => {
        setBannerConfig((prev) => ({
            ...prev,
            imgUrl: sessionStorage.getItem('imgUrl'),
            bgColor: sessionStorage.getItem('bgColor'),
        }))
    }, [])

    useEffect(() => {
        if (paramCheck > 6) navigate('/')
        if (params.id) {
            routeParamCheck(params.id)
        }
        if (paramCheck >= 3 && !token) {
            navigate('/')
        }
    }, [params.id, token])
    const handlePathCheck = () => {
        if (recruitNo) {
            const check = location.pathname.includes(recruitNo)
            return check
        }
    }

    // 라우트 가드
    useEffect(() => {
        const parmsId = params.id ? +params.id : 0
        const currentPage = current ? +current : 0
        const agreeStorage = sessionStorage.getItem('agree')
            ? JSON.parse(sessionStorage.getItem('agree') || '')
            : null
        if (end === 'true' || !handlePathCheck()) {
            alert('비정상적인 접근 입니다.')
            navigate('/')
        }
        if (!currentPage) {
            if (parmsId > 2) {
                navigate(`/`)
                alert('비정상적인 접근 입니다.')
            }
        }

        if (
            !agreeStorage?.personal_information_yn &&
            !agreeStorage?.service_terms_yn &&
            parmsId > 1
        ) {
            navigate(`/`)
            alert('비정상적인 접근 입니다.')
        }
    }, [end])

    return (
        <AppLayout
            src={bannerConfig.imgUrl || ''}
            bgColor={bannerConfig.bgColor || ''}
        >
            <Layout>
                <ProgressArea>
                    <ProgressBar id={params.id} />
                </ProgressArea>
                {/* {children} */}
                {params.id === '1' && (
                    <AgreeForm
                        agree={agree}
                        setAgree={setAgree}
                        onNext={handleNextRoute}
                        onPrev={handlePrevRoute}
                    />
                )}
                {params.id === '2' && (
                    <RequireUserInfo
                        terms={agree}
                        onNext={handleNextRoute}
                        onPrev={handlePrevRoute}
                    />
                )}
                {params.id === '3' && (
                    <UserSelection
                        onNext={handleNextRoute}
                        onPrev={handlePrevRoute}
                    />
                )}
                {params.id === '4' && (
                    <SelfIntroduction
                        onNext={handleNextRoute}
                        onPrev={handlePrevRoute}
                    />
                )}
                {params.id === '5' && (
                    <FileUploadForm
                        onNext={handleNextRoute}
                        onPrev={handlePrevRoute}
                    />
                )}
                {params.id === '6' && <RecruitSuccess />}
            </Layout>
        </AppLayout>
    )
}

export default Apply

const Layout = styled.div`
    // margin-bottom: 90px;
    height: 100%;
`

const ProgressArea = styled.div`
    margin: 50px 0 60px 0;
`

const PrevWithNextArea = styled.div`
    display: flex;
    justify-content: center;
    .button_form {
        display: flex;
        justify-content: space-between;
        width: 330px;
    }
`
