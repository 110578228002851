import styled from '@emotion/styled'
import React from 'react'
import colors from '../../utils/colors'

interface Props {
    children?: React.ReactNode
    htmlText: string
}
const AgreeArea: React.FC<Props> = ({ children, htmlText }) => {
    return (
        <Layout dangerouslySetInnerHTML={{ __html: htmlText }}>
            {children}
        </Layout>
    )
}

export default React.memo(AgreeArea)

const Layout = styled.div`
    overflow-x: scroll;
    width: 300px;
    height: 330px;
    padding: 16px 16px;
    border: 1px solid #ddd;

    box-sizing: border-box;

    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: ${colors.textBoldColor};
`
