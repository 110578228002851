const colors = {
    mainColor: '#196EE5',
    subColor: '#014098',
    confirmDisabledColor: '#E3ECFA',
    disabledColor: '#EBEBEB',
    gray: '#D7D7D7',
    textGray: '#999999',
    borderGray: '#CCCCCC',
    confirmGreen: '#85CD14',
    warningColor: '#F94733',
    warningSubColor: '#FF5B48',
    textBoldColor: '#333333',
    textColor: '#555555',
    inputTextColor: '#000000',
}

export default colors
