import React, { useEffect, useState } from 'react'
import ProgressItem from './ProgressItem'
import styled from '@emotion/styled'
import colors from '../../utils/colors'

interface Props {
    id?: string
}
const ProgressBar: React.FC<Props> = ({ id = 0 }) => {
    const [barGauge, setBarGauge] = useState(0)
    useEffect(() => {
        setBarGauge(19.3 * (+id - 1))
    }, [id])
    useEffect(() => {
        if (barGauge > 96.5) {
            setBarGauge(96.5)
        }
    }, [barGauge])

    const currentCheck = (num: number, type?: 'color') => {
        if (+id > num) {
            return colors.confirmGreen
        }
        if (+id === num) {
            return type === 'color' ? colors.textBoldColor : colors.mainColor
        }
        if (+id < num) {
            return colors.disabledColor
        }
        return '#FEFEFE'
    }
    return (
        <Layout>
            <ProgressItem
                num={1}
                text={'약관동의'}
                check={+id > 1}
                background={currentCheck(1)}
                color={currentCheck(1, 'color')}
                numColor={+id === 1 ? '#FEFEFE' : colors.borderGray}
            />
            <ProgressItem
                num={2}
                text={'인적 정보 입력'}
                check={+id > 2}
                background={currentCheck(2)}
                color={currentCheck(2, 'color')}
                numColor={+id === 2 ? '#FEFEFE' : colors.borderGray}
            />
            <ProgressItem
                num={3}
                text={'전형 선택'}
                check={+id > 3}
                background={currentCheck(3)}
                color={currentCheck(3, 'color')}
                numColor={+id === 3 ? '#FEFEFE' : colors.borderGray}
            />
            <ProgressItem
                num={4}
                text={'이력 및 소개 작성'}
                check={+id > 4}
                background={currentCheck(4)}
                color={currentCheck(4, 'color')}
                numColor={+id === 4 ? '#FEFEFE' : colors.borderGray}
            />
            <ProgressItem
                num={5}
                text={'관련 파일 첨부'}
                check={+id > 5}
                background={currentCheck(5)}
                color={currentCheck(5, 'color')}
                numColor={+id === 5 ? '#FEFEFE' : colors.borderGray}
            />
            <ProgressItem
                num={6}
                text={'전형 지원하기'}
                check={+id === 6}
                background={currentCheck(+id === 5 ? 6 : 5)}
                color={currentCheck(+id === 5 ? 6 : 5, 'color')}
                numColor={+id === 6 ? '#FEFEFE' : colors.borderGray}
            />
            <DefaultBar />
            <Bar width={barGauge} />
        </Layout>
    )
}

export default ProgressBar

const Layout = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
`

const DefaultBar = styled.div`
    position: absolute;
    left: 6px;
    top: 30%;
    width: 97%;
    height: 4px;
    background: #d9d9d9;
    z-index: -1;
`
const Bar = styled.div<{ width: number }>`
    position: absolute;
    left: 6px;
    top: 30%;
    width: ${({ width }) => width}%;
    height: 4px;
    background: ${colors.confirmGreen};
    z-index: -1;
`
