import styled from '@emotion/styled'
import React from 'react'
import colors from '../../utils/colors'

interface Props {
    title: string
}
const RequireText: React.FC<Props> = ({ title }) => {
    if (title === '*')
        return (
            <P fSize={'16'} fWeight={true}>
                *
            </P>
        )
    else {
        return <P>{title}</P>
    }
}

export default RequireText

const P = styled.p<{ fSize?: string; fWeight?: boolean }>`
    font-size: ${({ fSize }) => (fSize ? fSize : 14)}px;
    font-weight: ${({ fWeight }) => (fWeight ? 'bold' : '')};
    line-height: 17px;
    color: ${colors.warningSubColor};
`
