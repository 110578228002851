import { useEffect, useState } from 'react'

export const useDelayEffect = (delay: number) => {
    const [isDelay, setIsDelay] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsDelay(false)
        }, delay)
        return () => {
            clearTimeout(timer)
        }
    }, [delay])
    return isDelay
}

export const useDelay = (delay: number) => {
    const [delayed, setDelayed] = useState(false)
    const handler = (): void => {
        setDelayed(true)
        setTimeout(() => {
            setDelayed(false)
        }, delay)
    }
    return [delayed, handler]
}
