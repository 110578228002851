import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import AgreeArea from '../elements/AgreeArea'
import styled from '@emotion/styled'
import PrevWithNextArea from './PrevWithNextArea'
import { Agree } from '../../types/Agree'
import Modal from '../Modal'
import { agreeText, agreeTitle } from '../../config/text'
import SingleCheckIcon from '../icon/SingleCheckIcon'
import colors from '../../utils/colors'

interface Props {
    onNext: () => void
    onPrev: () => void
    agree: Agree
    setAgree: Dispatch<SetStateAction<Agree>>
}
const AgreeForm: React.FC<Props> = ({ onNext, onPrev, agree, setAgree }) => {
    const agreeStorage = sessionStorage.getItem('agree')
        ? JSON.parse(sessionStorage.getItem('agree') || '')
        : null
    const [success, setSuccess] = useState(false)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target
        setAgree((prev) => ({ ...prev, [name]: checked }))
    }

    const handleSessionChecked = () => {
        if (agreeStorage) {
            setAgree(agreeStorage)
        }
    }
    useEffect(() => {
        handleSessionChecked()
    }, [])

    useEffect(() => {
        if (agree.personal_information_yn && agree.service_terms_yn) {
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    }, [agree.personal_information_yn, agree.service_terms_yn])

    return (
        <Layout>
            <form className="agree_form" onSubmit={handleSubmit}>
                <div>
                    <TitleArea>
                        <CheckForm>
                            <Label htmlFor="service_terms_yn">
                                <Input
                                    name={'service_terms_yn'}
                                    id={'service_terms_yn'}
                                    type={'checkbox'}
                                    checked={agree.service_terms_yn}
                                    onChange={handleCheck}
                                />
                                {agree.service_terms_yn && (
                                    <SingleCheckIcon width={18} height={18} />
                                )}
                            </Label>
                            <h3
                                onClick={() => {
                                    setAgree({
                                        ...agree,
                                        service_terms_yn:
                                            !agree.service_terms_yn,
                                    })
                                }}
                            >
                                서비스 이용약관(필수)
                            </h3>
                        </CheckForm>
                        <div className="link">
                            <a
                                href="https://docs.google.com/document/d/1Xj5iuNmyzOn7jr19Uegv12PKEkAMFY8Wp-4ZltMyvUE/view"
                                target={'_blank'}
                            >
                                전문보기
                            </a>
                        </div>
                    </TitleArea>
                    <AgreeArea htmlText={agreeText.service}></AgreeArea>
                </div>
                <div>
                    <TitleArea>
                        <CheckForm>
                            <Label htmlFor="personal_information_yn">
                                <Input
                                    name={'personal_information_yn'}
                                    id={'personal_information_yn'}
                                    type={'checkbox'}
                                    checked={agree.personal_information_yn}
                                    onChange={handleCheck}
                                />
                                {agree.personal_information_yn && (
                                    <SingleCheckIcon width={18} height={18} />
                                )}
                            </Label>

                            <h3
                                onClick={() => {
                                    setAgree({
                                        ...agree,
                                        personal_information_yn:
                                            !agree.personal_information_yn,
                                    })
                                }}
                            >
                                개인 정보 사용 동의 (필수)
                            </h3>
                        </CheckForm>
                        <div className="link">
                            <a
                                href="https://docs.google.com/document/d/12b_Eyq7fyZpsz2Da9RI8OFYvgDDAN4Zf2OOokcD0dMc/view"
                                target={'_blank'}
                            >
                                전문보기
                            </a>
                        </div>
                    </TitleArea>
                    <AgreeArea htmlText={agreeText.personal}></AgreeArea>
                </div>
                <div>
                    <TitleArea>
                        <CheckForm>
                            <Label htmlFor="marketing_yn">
                                <Input
                                    name={'marketing_yn'}
                                    id={'marketing_yn'}
                                    type={'checkbox'}
                                    checked={agree.marketing_yn}
                                    onChange={handleCheck}
                                />
                                {agree.marketing_yn && (
                                    <SingleCheckIcon width={18} height={18} />
                                )}
                            </Label>
                            <h3
                                onClick={() => {
                                    setAgree({
                                        ...agree,
                                        marketing_yn: !agree.marketing_yn,
                                    })
                                }}
                            >
                                마케팅 동의 (선택)
                            </h3>
                        </CheckForm>
                            {/*
                            <div className="link">
                                <a
                                    href="https://temp.link"
                                    target={'_blank'}
                                >
                                    전문보기
                                </a>
                            </div>
                            */}
                    </TitleArea>
                    <AgreeArea htmlText={agreeText.marketing}></AgreeArea>
                </div>
            </form>
            <PrevWithNextArea
                onNext={onNext}
                onPrev={onPrev}
                success={success}
            />
            {/* {showModal && (
                <Modal
                    setShowModal={setShowModal}
                    children={
                        <Contents>
                            <div className="header">
                                <div className="title">{subject}</div>
                                <div onClick={() => setShowModal(false)}>
                                    <CancelIcon />
                                </div>
                            </div>
                            <div className="body">{Text}</div>
                        </Contents>
                    }
                />
            )} */}
        </Layout>
    )
}

export default AgreeForm

const Layout = styled.div`
    margin-bottom: 60px;
    .agree_form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
    }
`

const Input = styled.input`
    display: none;
`
const Label = styled.label`
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid ${colors.mainColor};
    border-radius: 2px;
    cursor: pointer;
`

const TitleArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 12px 0;
    .link {
        a {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            color: #478bea;
            text-decoration: none;
            &:hover {
                color: blue;
            }
        }

        // cursor: pointer;
    }
    h3 {
        cursor: pointer;
    }
`
const CheckForm = styled.div`
    display: flex;
    align-items: center;
    h3 {
        margin: 0 6px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: ${colors.textBoldColor};
    }
`

const Contents = styled.div<{ width?: number; height?: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    width: ${({ width }) => (width ? width : 620)}px;
    height: ${({ height }) => (height ? height : 468)}px;

    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
    box-shadow: 0px 0px 8px ${colors.borderGray};
    border-radius: 10px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin-bottom: 30px;
    }
    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
    }
    .body {
        overflow-x: scroll;
        width: 560px;
        height: 387px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }
`

const CancelIcon = styled.i`
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/icon/Cancel.svg');
    cursor: pointer;
`
