export const agreeText: { [key: string]: string } = {
    service: `
    이용약관
    <br/><br/>
    제1조(목적)
    <br/>
     본 약관은 주식회사 갤럭틱엔터테인먼트 (이하
        "회사")가 운영하는 "서비스"를 이용함에 있어 "회사"와
        회원간의 이용 조건 및 제반 절차, 권리, 의무 및 책임사항,
        기타 필요한 사항을 규정함을 목적으로 한다.
        <br/><br/>
        제2조(용어의정의)
        <br/>
        이 약관에서 사용하는 용어의 정의는 아래와 같다.
        <br/>
        ① "사이트"라 함은 회사가 서비스를 "회원"에게 제공하기
        위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의
        영업장 또는 회사가 운영하는 웹사이트, 모바일웹, 앱 등의
        서비스를 제공하는 모든 매체를 통칭하며, 통합된 하나의
        회원 계정(아이디 및 비밀번호)을 이용하여 서비스를
        제공받을 수 있는 아래의 사이트를 말한다. 
        <br/>
        ② "서비스"라
        함은 회사가 운영하는 사이트를 통해 개인이 구직, 교육
        등의 목적으로 등록하는 자료를 DB화하여 각각의 목적에
        맞게 분류 가공, 집계하여 정보를 제공하는 서비스와
        사이트에서 제공하는 모든 부대 서비스를 말한다. 
        </br>
        ③ "회원"이라 함은 "회사"가 제공하는 서비스를 이용하거나
        이용하려는 자로, 이메일 인증, 입사 지원서 작성 등의
        절차를 거쳐 확인 이메일 등을 통해 회사로부터 회원으로
        인정받은 "개인회원"을 말한다.
        <br/>
        ④ "비회원"이라 함은
        회원가입절차를 거치지 않고 "회사"가 제공하는 서비스를
        이용하거나 하려는 자를 말한다.
        <br/><br/>
        제3조(약관의 명시와 개정)
        <br/>
        ① "회사"는 이 약관의 내용과 상호, 영업소 소재지,
        대표자의 성명, 사업자등록번호, 연락처 등을 이용자가 알
        수 있도록 초기 화면에 게시하거나 기타의 방법으로
        이용자에게 공지해야 한다. 
        <br/>
        ② "회사"는 약관의 규제에 관한
        법률, 전기통신기본법, 전기통신사업법, 정보통신망
        이용촉진 및 정보보호 등에 관한 법률 등 관련법을 위배하지
        않는 범위에서 이 약관을 개정할 수 있다. 
        <br/>
        ③ "회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
        현행약관과 함께 그 개정약관의 적용일자 7일 전부터
        적용일자 전일까지 공지한다. 단 "회원"의 권리, 의무에
        중대한 영향을 주는 변경의 경우에는 적용일자 30일 전부터
        공지하도록 한다. 
        <br/>
        ④ "회원"은 변경된 약관에 대해 거부할
        권리가 있다. "회원"은 변경된 시점으로부터 15일 이내에
        거부의사를 표명할 수 있다. "회원"이 거부하는 경우 본
        서비스 제공자인 "회사"는 15일의 기간을 정하여 "회원"에게
        사전 통지 후 당해 "회원"과의 계약을 해지할 수 있다.
        만약, "회원"이 거부의사를 표시하지 않거나, 전항에 따라
        시행일 이후에 "서비스"를 이용하는 경우에는 동의한 것으로
        간주한다. 
        <br/><br/>
        제4조(약관의 해석)
        <br/>
        ① 이 약관에서 규정하지 않은
        사항에 관해서는 약관의 규제에 관한 법률, 전기통신기본법,
        전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
        관한 법률 등의 관계법령에 따른다. 
        <br/>
        ② 각 사이트 및 서비스
        이용약관이 있는 경우에는 서비스 이용약관이 우선한다. 
        <br/>
        ③ "회원"이 "회사"와 개별 계약을 체결하여 서비스를 이용하는
        경우에는 개별 계약이 우선한다.
        <br/><br/>
        제5조(이용계약의 성립) 
        <br/>
        ① "회사"의 서비스 이용계약(이하 '이용계약')은 서비스를
        이용하고자 하는 자가 본 약관과 개인정보처리방침을 읽고
        "동의" 또는 "확인" 버튼을 누른 경우 본 약관에 동의한
        것으로 간주한다.
        <br/>
        ② 제1항 신청에 있어 "회사"는 "회원"의
        종류에 따라 전문기관을 통한 실명확인 및 본인인증을
        요청할 수 있으며, 이 때 "회원"은 본인인증에 필요한 이름,
        생년월일, 연락처 등을 제공하여야 한다. 
        <br/>
        ③ "회원"이 이용신청(회원가입 신청) 작성 후에 "회사"가 웹상의 안내
        및 전자메일로 "회원"에게 통지함으로써 이용계약이
        성립된다. 
        <br/><br/>
        제6조(이용신청의 승낙과 제한) 
        <br/>
        ① "회사"는 전조의 규정에 의한 이용신청 고객에 대하여 업무수행상
        또는 기술상 지장이 없는 경우에는 원칙적으로 접수순서에
        따라 서비스 이용을 승낙한다. 
        <br/>
        ② "회사"는 아래사항에 해당하는 경우에 대해서는 서비스 이용신청을 승낙하지
        아니한다. 
        <br/>
        1. 실명이 아니거나 타인의 명의를 이용하여 신청한 경우 
        <br/>
        2. 이용계약 신청서의 내용을 허위로
        기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우
        <br/>
        3. 만 15세 미만의 아동이 신청한 경우. 다만, 만 13세 이상
        만 15세 미만의 아동으로서 노동부장관 발급의 취직인허증이
        있는 경우에는 그러하지 아니한다. 
        <br/>
        4. 이용자의 귀책사유로
        인하여 승인이 불가능하거나 기타 규정한 제반 사항을
        위반하여 신청하는 경우 
        <br/>
        ③ "회사"는 아래사항에 해당하는
        경우에는 그 신청에 대하여 승낙제한 사유가 해소될 때까지
        승낙을 유보할 수 있다. 
        <br/>
        1. "회사"가 설비의 여유가 없는 경우 
        <br/> 
        2. "회사"의 기술상 지장이 있는 경우 
        <br/>
        3. 기타 "회사"의 귀책사유로 이용승낙이 곤란한 경우
        <br/><br/>
        제7조(서비스의 내용) 
        <br/>
        ① "회사"는 제2조 2항의 서비스를
        제공할 수 있으며 그 내용은 다음 각 호와 같다. 
        <br/>
        1. 이력서 등록 및 인재정보 게재 서비스 
        <br/>
        2. 온라인 입사지원 서비스
        <br/>
        3. 구인/구직과 관련된 제반 서비스 
        <br/>
        4. 이용자간의 교류와 소통에 관련한 서비스 
        <br/>
        5. 자료거래에 관련한 서비스 
        <br/>
        6. 기타
        "회사"가 추가 개발, 편집, 재구성하거나 제휴계약 등을
        통해 "회원"에게 제공하는 일체의 서비스 
        <br/>
        ② "회사"는 필요한
        경우 서비스의 내용을 추가 또는 변경할 수 있다. 단, 이
        경우 "회사"는 추가 또는 변경내용을 회원에게 공지해야
        한다. 
        <br/><br/>
        제8조(회원, 이력서 정보) 
        <br/>
        ① "회원"의 이력서는
        개인이 회원가입 또는 이력서 작성 및 수정 시 희망한
        형태로 등록 및 제공된다.
        <br/>
        ② "회사"는 "회원"이 입사 지원을
        하였을 경우, 기업회원의 이력서 열람 및 각종 포지션
        제안에 동의한 것으로 간주하며 "회사"는 기업회원에게
        유료로 이력서 열람 서비스를 제공할 수 있다. 
        <br/>
        ③ "회사"는
        안정적인 서비스를 제공하기 위해 테스트 및 모니터링
        용도로 "사이트" 운영자가 이력서 정보를 열람하도록 할 수
        있다. 
        <br/>
        ④ "회사"는 "회원"의 자유로운 선택에 의하여 등록 및
        제공되는 이력서의 정보를 기준(바탕)으로 구직활동에 보다
        유익한 서비스를 제공하기 위하여 이를 개발, 편집,
        재구성한 통계 자료로 활용 할 수 있다. 
        <br/><br/>
        제9조(제휴를 통한 서비스)
        <br/>
        ① "회사"는 제휴 관계를 체결한 여타 인터넷 웹
        사이트 및 공공기관 또는 기업체에게 사이트에 등록한
        "회원"의 이력서 정보가 열람될 수 있도록 서비스를 제공할
        수 있다. 단, 제휴 서비스를 통해 노출되는 이력서의 연락처
        정보는 "회원"이 이력서 등록 시 선택한 마케팅 활용 여부에
        따라 제공된다. 
        <br/>
        ② "회사"는 제휴를 통해 타 사이트 및
        매체에 등록될 수 있음을 고지해야 하며, 제휴 사이트
        목록을 사이트내에서 상시 열람할 수 있도록 해야 한다. 단,
        등록의 형태가 "회사"가 직접 구축하지 않고, 제휴사가 xml
        또는 api 형태로 "회사"로부터 제공 받아 구축한 매체
        목록은 본 약관 외 별도의 제휴 리스트에서 열람할 수
        있도록 한다. 
        <br/>
        ③ "회사"는 "회원"이 공개를 요청한 이력서에
        한해 제휴를 맺은 타 사이트에 이력서 정보를 제공한다. (본
        약관 시행일 현재에는 제휴를 통한 타 사이트 및 매체는
        없다.) 
        <br/>
        ④ "본조 제③호" 제휴를 통한 사이트의 변동사항 발생
        시 공지사항을 통해 고지 후 진행 한다. 
        <br/><br/>
        제10조(서비스의 요금) 
        <br/>
        ① "회원" 가입과 이력서 작성은 무료이다. 추후
        "사이트"의 개발에 따른 추가 기능을 이용하기 위한 별도의
        서비스는 유료로 제공될 수 있다. 
        <br.<
        ② "회사"는 유료서비스를
        제공할 경우 사이트에 요금에 대해서 공지를 하여야 한다. ③
        "회사"는 유료서비스 이용금액을 서비스의 종류 및 기간에
        따라 "회사"가 예고 없이 변경할 수 있다. 다만, 변경
        이전에 적용 또는 계약한 금액은 소급하여 적용하지
        아니한다. 
        
        제11조(서비스 요금의 환불)
        
        ① "회사"는 다음 각
        호에 해당하는 경우 이용요금을 환불한다. 단, 각 당사자의
        귀책사유에 따라 환불 조건이 달라질 수 있다. 
        1. 유료서비스 이용이 개시되지 않은 경우 
        2. 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우 
        3. 유료서비스 신청 후 "회원"의 사정에 의해 서비스가 취소될 경우 
        ② "회사"가 본 약관 제19조에 따라
        가입해지/서비스중지/자료삭제를 취한 경우, "회사"는
        "회원"에게 이용요금을 환불하지 않으며, 별도로 "회원"에게
        손해배상을 청구할 수 있다. ③ 이용료를 환불받고자 하는
        회원은 고객센터로 환불을 요청해야 한다. ④ "회사"는 환불
        요건에 부합하는 것으로 판단될 경우, 각 서비스 환불
        안내에 따라 유료이용 계약 당시 상품의 정가 기준으로
        서비스 제공된 기간에 해당하는 요금을 차감한 잔액을
        환불한다. 제12조(서비스 이용시간) ① "회사"는 특별한
        사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공한다.
        다만, "회사"는 서비스의 종류나 성질에 따라 제공하는
        서비스 중 일부에 대해서는 별도로 이용시간을 정할 수
        있으며, 이 경우 "회사"는 그 이용시간을 사전에 회원에게
        공지 또는 통지하여야 한다. ② "회사"는 자료의 가공과
        갱신을 위한 시스템 작업시간, 장애 해결을 위한 보수작업
        시간, 정기 PM 작업, 시스템 교체작업, 회선 장애 등이
        발생한 경우 일시적으로 서비스를 중단할 수 있으며 계획된
        작업의 경우 공지란에 서비스 중단 시간과 작업 내용을
        알려야 한다. 다만, "회사"가 사전에 통지할 수 없는
        부득이한 사유가 있는 경우 사후에 통지할 수 있다.
        제13조(서비스 제공의 중지) ① "회사"는 다음 각 호에
        해당하는 경우 서비스의 제공을 중지할 수 있다. 1. 설비의
        보수 등 "회사"의 필요에 의해 사전에 회원들에게 통지한
        경우 2. 기간통신사업자가 전기통신서비스 제공을 중지하는
        경우 3. 기타 불가항력적인 사유에 의해 서비스 제공이
        객관적으로 불가능한 경우 ② 전항의 경우, "회사"는 기간의
        정함이 있는 유료서비스 이용자들에게는 그 이용기간을
        연장하거나 환불 등의 방법으로 손실을 보상하여야 한다.
        제14조(정보의 제공 및 광고의 게재) ① "회사"는 "회원"에게
        서비스 이용에 필요가 있다고 인정되거나 서비스 개선 및
        회원대상의 서비스 소개 등의 목적으로 하는 각종 정보에
        대해서 전자우편이나 서신우편을 이용한 방법으로 제공할 수
        있다. ② "회사"는 제공하는 서비스와 관련되는 정보 또는
        광고를 서비스 화면, 홈페이지, 전자우편 등에 게재할 수
        있으며, 광고가 게재된 전자우편을 수신한 "회원"은
        수신거절을 "회사"에게 할 수 있다. ③ "회사"는 서비스상에
        게재되어 있거나 본 서비스를 통한 광고주의 판촉활동에
        회원이 참여하거나 교신 또는 거래를 함으로써 발생하는
        모든 손실과 손해에 대해 책임을 지지 않는다. ④ 본
        서비스의 "회원"은 서비스 이용 시 노출되는 광고게재에
        대해 동의 하는 것으로 간주한다. 제15조(자료내용의 책임과
        "회사"의 정보 수정 권한) ① 자료내용은 "회원"이 등록한
        개인정보 및 이력서와 사이트에 게시한 게시물을 말한다. ②
        "회원"은 자료 내용 및 게시물을 사실에 근거하여 성실하게
        작성해야 하며, 만일 자료의 내용이 사실이 아니거나
        부정확하게 작성되어 발생하는 모든 책임은 "회원"에게
        있다. ③ 모든 자료내용의 관리와 작성은 "회원" 본인이 하는
        것이 원칙이나 사정상 위탁 또는 대행관리를 하더라도
        자료내용의 책임은 "회원"에게 있으며 "회원"은 주기적으로
        자신의 자료를 확인하여 항상 정확하게 관리가 되도록
        노력해야 한다. ④ "회사"는 "회원"이 등록한 자료 내용에
        오자, 탈자 또는 사회적 통념에 어긋나는 문구와 내용이
        있을 경우 이를 언제든지 수정할 수 있다. ⑤ "회원"이
        등록한 자료로 인해 타인(또는 타법인)으로부터 허위사실 및
        명예훼손 등으로 삭제 요청이 접수된 경우 "회사"는
        "회원"에게 사전 통지 없이 본 자료를 삭제할 수 있으며
        삭제 후 메일 등의 방법으로 통지할 수 있다. 제16조(자료
        내용의 활용 및 취급) ① "회원"이 선택하거나 입력한 정보는
        취업 및 관련 동향의 통계 자료로 구성, 활용될 수 있으며
        그 자료는 매체를 통한 언론 배포 또는 제휴사에게 제공될
        수 있다. 단, 개인을 식별할 수 있는 형태가 아니어야 한다.
        ② '사이트'에서 정당한 절차를 거쳐 기업회원이 열람한
        "회원"의 이력서 정보는 해당 기업의 인사자료이며 이에
        대한 관리 권한은 해당 기업의 정책에 의한다. ③ "회사"는
        '사이트'의 온라인 입사지원 시스템을 통해 지원한 "회원"의
        이력서 열람 여부 및 기업회원이 제공한 채용정보에
        입사지원한 구직자들의 각종 통계데이터를 "회원"에게
        제공할 수 있다. 제17조("회사"의 의무) ① "회사"는 본
        약관에서 정한 바에 따라 계속적, 안정적으로 서비스를
        제공할 수 있도록 최선의 노력을 다해야 한다. ② "회사"는
        서비스와 관련한 "회원"의 불만사항이 접수되는 경우 이를
        즉시 처리하여야 하며, 즉시 처리가 곤란한 경우에는 그
        사유와 처리일정을 서비스 화면 또는 기타 방법을 통해 동
        "회원"에게 통지하여야 한다. ③ "회사"는 유료 결제와
        관련한 결제 사항 정보를 1년 이상 보존한다. 다만 회원
        자격이 없는 회원은 예외로 한다. ④ 천재지변 등 예측하지
        못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가
        중단될 경우 이에 대한 손해에 대해서는 "회사"가 책임을
        지지 않는다. 다만 자료의 복구나 정상적인 서비스 지원이
        되도록 최선을 다할 의무를 진다. ⑤ "회원"의 자료를 본
        서비스 이외의 목적으로 제3자에게 제공하거나 열람시킬
        경우 반드시 "회원"의 동의를 얻어야 한다. 제18조("회원"의
        의무) ① "회원"은 관계법령과 본 약관의 규정 및 기타
        "회사"가 통지하는 사항을 준수하여야 하며, 기타 "회사"의
        업무에 방해되는 행위를 해서는 안 된다. ② "회원"이 신청한
        유료서비스는 등록 또는 신청과 동시에 "회사"와 채권, 채무
        관계가 발생하며, "회원"은 이에 대한 요금을 지정한 기일
        내에 납부하여야 한다. ③ "회원"이 결제 수단으로
        신용카드를 사용할 경우 비밀번호 등 정보 유실 방지는
        "회원" 스스로 관리해야 한다. 단, 사이트의 결함에 따른
        정보유실의 발생에 대한 책임은 "회원"의 의무에 해당하지
        아니한다. ④ "회원"은 서비스를 이용하여 얻은 정보를
        "회사"의 사전동의 없이 복사, 복제, 번역, 출판, 방송
        기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없다.
        ⑤ "회원"은 본 서비스를 건전한 취업 및 경력관리 이외의
        목적으로 사용해서는 안되며 이용 중 다음 각 호의 행위를
        해서는 안 된다. 1. 다른 회원의 정보를 부정 사용하는 행위
        2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된
        행위 3. 타인의 명예를 훼손하거나 모욕하는 행위 4. 타인의
        지적재산권 등의 권리를 침해하는 행위 5. 해킹행위 또는
        바이러스의 유포 행위 6. 타인의 의사에 반하여 광고성 정보
        등 일정한 내용을 계속적으로 전송하는 행위 7. 서비스의
        안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는
        행위 8. 사이트의 정보 및 서비스를 이용한 영리 행위 9. 그
        밖에 선량한 풍속, 기타 사회질서를 해하거나 관계법령에
        위반하는 행위 제19조("회원"의
        가입해지/서비스중지/자료삭제) ① "회원"은 언제든지
        회원탈퇴 또는 이력서 등록을 해지하기 위해 고객센터 또는
        회원탈퇴 메뉴 등을 통하여 이용계약 해지 신청을 할 수
        있으며, "회사"는 관련법 등이 정하는 바에 따라 이를
        처리한다. ② 다음의 사항에 해당하는 경우 "회사"는 사전
        동의없이 가입해지나 서비스 중지, 이력서 삭제 조치를 취할
        수 있다. 1. 회원의 의무를 성실하게 이행하지 않았을 때 2.
        규정한 유료서비스 이용 요금을 납부하지 않았을 때 3. 본
        서비스 목적에 맞지 않는 분야에 정보를 활용하여 사회적
        물의가 발생한 때 4. 회원이 등록한 정보의 내용이 사실과
        다르거나 조작되었을 때 5. 본 서비스와 관련하여 회사 또는
        제3자의 명예를 훼손하였을 때 6. 기타 위 각호에 준하는
        사유가 발생하였을 때 ③ "회원"이 유료서비스를 이용하는 중
        "회사"의 책임에 의해 정상적인 서비스가 제공되지 않을
        경우 "회원"은 본 서비스의 해지를 요청할 수 있으며
        "회사"는 기간의 정함이 있는 유료서비스의 경우에는
        해지일까지 이용일수를 1일기준금액으로 계산하여
        이용금액을 공제후 환급하고, 이용 건수의 정함이 있는
        유료서비스의 경우에는 기 사용분을 1건기준금액으로
        계산하여 이용금액을 공제후 환급한다. ④ "회사"는 회원
        가입이 해지된 경우에는 개인정보처리방침 중,
        02.개인정보의 처리 및 보유 기간 규정에 따른다. ⑤
        개인정보보호를 위하여 개인정보 보유기간 동안 "사이트"를
        이용하지 않은 경우, "아이디"를 "휴면계정"으로 분리하여
        해당 계정의 이용을 중지할 수 있다. 이 경우 "회사"는
        "휴면계정 처리 예정일"로부터 30일 이전에 해당사실을
        전자메일, 서면, SMS 중 하나의 방법으로 사전통지하며
        "회원"이 직접 본인확인을 거쳐, 다시 "사이트" 이용
        의사표시를 한 경우에는 "사이트" 이용이 가능하다.
        "휴면계정"으로 분리 보관된 개인정보는 5년간 보관 후
        지체없이 파기한다. 제20조(손해배상) ① "회사"가 이 약관의
        규정을 위반한 행위로 "회원"에게 손해를 입히거나 기타
        "회사"가 제공하는 모든 서비스와 관련하여 "회사"의 책임
        있는 사유로 인해 "회원"에게 손해가 발생한 경우 "회사"는
        그 손해를 배상하여야 한다. ② "회사"는 책임 있는 사유로
        제공한 정보가 사실과 달라 "회원"이 손해를 입었을 경우에
        "회사"는 그 손해를 배상하여야 한다. ③ "회원"이 이 약관의
        규정을 위반한 행위로 "회사" 및 제3자에게 손해를 입히거나
        "회원"의 책임 있는 사유에 의해 "회사" 및 제3자에게
        손해를 입힌 경우에는 "회원"은 그 손해를 배상하여야 한다.
        ④ 타 회원(개인회원, 기업회원 모두 포함)의 귀책사유로
        "회원"의 손해가 발생한 경우 "회사"는 이에 대한 배상
        책임이 없다. 제21조(양도 금지) "회원"의 서비스 받을
        권리는 제3자에게 양도, 대여, 증여 등으로 사용할 수 없다.
        제22조(이용요금 오류의 조정) "회사"는 이용요금과
        관련하여 오류가 있는 경우에 "회원"의 요청, 또는 "회사"의
        사전 통지에 의하여 다음에 해당하는 조치를 취한다. 1.
        과다납입한 요금에 대하여는 그 금액을 반환한다. 다만,
        "회원"이 동의할 경우 다음 달에 청구할 요금에서 해당
        금액만큼을 감하여 청구한다. 2. 요금을 반환받아야 할
        "회원"이 요금체납이 있는 경우에는 반환해야 할 요금에서
        이를 우선 공제하고 반환한다. 3. "회사"는 과소청구액에
        대해서는 익월에 합산청구한다. 제23조("회원"의
        개인정보보호) "회사"는 "회원"의 개인정보보호를 위하여
        노력해야 한다. "회원"의 개인정보보호에 관해서는
        정보통신망이용촉진 및 정보보호 등에 관한 법률,
        개인정보보호법에 따르고, "사이트"에 "개인정보처리방침"을
        고지한다. 제24조(신용정보의 제공 활용 동의) ① "회사"가
        회원가입과 관련하여 취득한 "회원"의 개인신용정보를
        타인에게 제공하거나 활용하고자 할 때에는 신용정보의 이용
        및 보호에 관한 법률 제23조의 규정에 따라 사전에 그 사유
        및 해당기관 또는 업체명 등을 밝히고 해당 "회원"의 동의를
        얻어야 한다. ② 본 서비스와 관련하여 "회사"가
        "회원"으로부터 신용정보의 이용 및 보호에 관한 법률에
        따라 타인에게 제공 활용에 동의를 얻은 경우 "회원"은
        "회사"가 신용정보 사업자 또는 신용정보 집중기관에 정보를
        제공하여 "회원"의 신용을 판단하기 위한 자료로
        활용하거나, 공공기관에서 정책자료로 활용되도록 정보를
        제공하는 데 동의한 것으로 간주한다. 제25조(분쟁의 해결)
        ① "회사"와 "회원"은 서비스와 관련하여 발생한 분쟁을
        원만하게 해결하기 위하여 필요한 모든 노력을 하여야 한다.
        ② 전항의 노력에도 불구하고, 동 분쟁에 관한 소송은
        "회사"의 주소지 관할법원으로 한다. 부칙 이 약관은 2024년
        1월 1일부터 시행한다.`,
    personal: `
    개인정보처리방침
    <br/><br/>
    < 주식회사 갤럭틱엔터테인먼트 >(이하 '(주)갤럭틱엔터테인먼트')는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.																
    <br/><br/>
    ○ 이 개인정보처리방침은 2024년 1월 1부터 적용됩니다.
    <br/><br/>                                            
    제1조(개인정보의 처리 목적)
    <br/>
    < (주)갤럭틱엔터테인먼트 > 는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.									
    <br/>
    1. 홈페이지 회원가입 및 관리<br/>
    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지 목적으로 개인정보를 처리합니다.									
    2. 민원사무 처리							
    <br/>
    민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
    3. 재화 또는 서비스 제공										
    <br/>
    서비스 제공, 맞춤서비스 제공, 본인인증, 입사지원 및 이력서 작성 등 취업 활동 서비스 제공을 목적으로 개인정보를 처리합니다.												
    4. 마케팅 및 광고에의 활용									
    <br/>
    신규 서비스(제품) 개발 및 맞춤 서비스 제공, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.												
    <br/><br/>
    제2조(개인정보의 처리 및 보유 기간)												
    <br/>
    ① < (주)갤럭틱엔터테인먼트 > 는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
    <br/>
    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.												
    <br/>
    1와 관련한 개인정보는 수집·이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.	
    보유근거 : 정보주체의 동의									
    보유기간 : 동의일로부터 1년												
    2와 관련한 개인정보는 수집·이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.	
    보유근거 : 정보주체의 동의									
    보유기간 : 동의일로부터 1년												
    3와 관련한 개인정보는 수집·이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.	
    보유근거 : 정보주체의 동의									
    보유기간 : 동의일로부터 1년												
    4와 관련한 개인정보는 수집·이용에 관한 동의일로부터까지 위 이용목적을 위하여 보유.이용됩니다.	
    보유근거 : 정보주체의 동의									
    보유기간 : 동의일로부터 1년												
    제3조(처리하는 개인정보의 항목) 		
                                
    ① < (주)갤럭틱엔터테인먼트 > 는 다음의 개인정보 항목을 처리하고 있습니다.					
    1< 홈페이지 회원가입 및 관리 >									
    필수항목 : 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 서비스 이용 기록
                
    2< 민원사무 처리 >										
    필수항목 : 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 서비스 이용 기록												
    3< 재화 또는 서비스 제공 >									
    필수항목 : 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 결혼여부, 신체정보, 서비스 이용 기록
    
    원활한 서비스 제공(취업 활동 서비스 제공)의 목적 상, 회원의 결혼여부 및 신체정보는 입사지원 시 경력단절여성·장애인 자격 적합 여부 판단을 목적으로만 이용됩니다.
    
    4< 마케팅 및 광고에의 활용 >									
    필수항목 : 이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름, 서비스 이용 기록												
    제4조(개인정보의 제3자 제공에 관한 사항)												
    ① < (주)갤럭틱엔터테인먼트 > 는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.												
    ② < (주)갤럭틱엔터테인먼트 > 는 본 처리방침 작성 시점에서 제3자에게 개인정보를 제공하고 있지 않습니다.												
    제5조(개인정보의 파기절차 및 파기방법)												
    ① < (주)갤럭틱엔터테인먼트 > 는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.												
    ② 개인정보 파기의 절차 및 방법은 다음과 같습니다.												
    1. 파기절차											
    < (주)갤럭틱엔터테인먼트 > 는 파기 사유가 발생한 개인정보를 선정하고, < (주)갤럭틱엔터테인먼트 >의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.												
    2. 파기방법											
    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.			
    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다												
    제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)												
    ① 정보주체는 (주)갤럭틱엔터테인먼트에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.												
    ② 제1항에 따른 권리 행사는 (주)갤럭틱엔터테인먼트에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)갤럭틱엔터테인먼트는 이에 대해 지체 없이 조치하겠습니다.												
    ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.												
    ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.																					
    ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.												
    ⑥ (주)갤럭틱엔터테인먼트는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.												
    제7조(개인정보의 안전성 확보조치에 관한 사항)												
    < (주)갤럭틱엔터테인먼트 > 는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.												
    1. 정기적인 자체 감사 실시									
    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.												
    2. 개인정보 취급 직원의 최소화 및 교육								
    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.																							
    3. 내부관리계획의 수립 및 시행									
    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.							
    4. 해킹 등에 대비한 기술적 대책												
    <(주)갤럭틱엔터테인먼트> 는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.																				
    5. 개인정보의 암호화												
    이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.												
    6. 접속기록의 보관 및 위변조 방지																				
    개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.												
    7. 개인정보에 대한 접근 제한																					
    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.																						
    8. 문서보안을 위한 잠금장치 사용												
    개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.												
    9. 비인가자에 대한 출입 통제																					
    개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.																							
    제8조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)															
    (주)갤럭틱엔터테인먼트 는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.												
    제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)																		
    행태정보의 수집·이용·제공 및 거부등에 관한 사항							
    (주)갤럭틱엔터테인먼트 는 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.																			
                        
    제10조(추가적인 이용·제공 판단기준)												
    < (주)갤럭틱엔터테인먼트 > 는 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.												
    이에 따라 < (주)갤럭틱엔터테인먼트 > 가 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.												
    ▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부												
    ▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부																							
    ▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부															
    ▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부																
    제11조(개인정보 보호책임자에 관한 사항) 																				
    ① (주)갤럭틱엔터테인먼트 는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.												
    ▶ 개인정보 보호 책임자										
    성명 : 천보성											
    직급 : 부장										
    연락처 : chunbs@galactic.co.kr						

    ▶ 개인정보 보호 관리자
    성명 : 위관규
    직급 : 차장
    연락처 : gkwee@galactic.co.kr

    ▶ 개인정보 처리자
    성명 : 최다현
    직급 : 대리
    연락처 : dhchoe@galactic.co.kr
											
    ② 정보주체께서는 (주)갤럭틱엔터테인먼트 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)갤럭틱엔터테인먼트 는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.															
    제12조(개인정보의 열람청구를 접수·처리하는 부서)												
    정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.												
    <br (주)갤럭틱엔터테인먼트 >는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. 	
    
                                                
    ▶ 개인정보 보호 담당부서									
    부서명 : 모니터링운영팀										
    연락처 : 02-6375-1487
										
    제13조(정보주체의 권익침해에 대한 구제방법)												
    정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.												
    1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)												
    2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)												
    3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)												
    4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)							
    「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.												
    ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.																				
    제14조(개인정보 처리방침 변경)												
    ① 이 개인정보처리방침은 2024년 1월 1부터 적용됩니다.	`,
    marketing: `
    마케팅 활용정보 제공에 관한 동의서
    <br/><br/>
    제1조(목적)
    <br/>
    (주)갤럭틱엔터테인먼트(이하 ‘회사’라 한다)의 채용 플랫폼 서비스(입사지원, 인사, 채용 알선 서비스, 이하 ‘서비스’라 한다)는 회원님에게 보다 다양한 정보를 제공하고, 서비스의 질을 향상시키기 위하여 당사의 제휴사에게 「개인정보보호법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률 」및 「신용정보의 이용 및 보호에 관한 법률」의 규정을 준수하여 회원님의 개인(신용)정보를 회사의 제휴사에게 상품소개 및 홍보 등 영업목적으로 제공할 수 있습니다.
    <br/><br/>
    제2조(수집 및 활용 관련 정보)
    <br/>
    (주)갤럭틱엔터테인먼트는 본 동의서 작성 시점에 제3자에게 마케팅을 목적으로 개인정보를 제공하고 있지 않습니다.
    <br/>
    <br/>
    제3조(제3자 정보제공의 동의 철회)
    <br/>
    신규회원 중 제3자 정보제공을 철회하고 싶은 회원은 이미 제3자에게 제공된 개인정보라 하더라도, 언제든지 열람, 정정, 삭제를 요구할 수 있습니다. 열람, 정정, 삭제 및 정보제공 등의 철회는 전화와 이메일 등을 통하여 본인 확인 후 요청할 수 있습니다. 
    이미 제공된 회원정보를 철회하는 데는 10일 내외의 시간이 소요됩니다. 활용 동의 철회를 요청하시더라도 위와 같은 이유로 해당 철회 적용 이전까지 마케팅에 활용될 수 있음을 알려드립니다.
    회사는 회원님의 소중한 정보를 보호하기 위하여 신속하게 처리되도록 최선의 노력을 다하겠습니다.
    `,
}
export const agreeTitle: { [key: string]: string } = {
    service: '서비스 이용약관',
    personal: '개인정보 수집 및 이용',
    marketing: '마케팅 정보 수신 동의',
}
