import styled from '@emotion/styled'
import React from 'react'
import colors from '../../utils/colors'

interface Props {
    title: string
    children?: React.ReactNode
}
const InputWithTitle: React.FC<Props> = ({ title, children }) => {
    return (
        <Layout>
            <div className="title_box">
                <label>{title}</label>
            </div>
            <div>{children}</div>
        </Layout>
    )
}

export default InputWithTitle

const Layout = styled.div`
    display: flex;
    flex-direction: column;

    .title_box {
        margin-bottom: 12px;
        label {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color: ${colors.textColor};
        }
    }
`
