import React, { useCallback, useEffect, useState } from 'react'
import AppLayout from '../components/AppLayout'
import TypeButton from '../components/elements/TypeButton'
import styled from '@emotion/styled'
import ReceiptPeriod from '../components/recruitment/ReceiptPeriod'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { get } from '../api/customAxios'
import { RecruitDetail } from '../types/Recruit'
import Modal from '../components/Modal'
import { useAppDispatch } from '../store'
import {
    setGlobalBg,
    setRecruitDetailInfo,
    setTypes,
} from '../store/slice/typeSlice'
import dayjs from 'dayjs'
import YoungPeople from '../components/recruitment/modal/YoungPeople'
import CareerInterrupted from '../components/recruitment/modal/CareerInterrupted'
import NearPoverty from '../components/recruitment/modal/NearPoverty'
import Handicapped from '../components/recruitment/modal/Handicapped'
import MultiCultural from '../components/recruitment/modal/MultiCultural'
import Normal from '../components/recruitment/modal/Normal'
import ModalPortal from '../components/elements/ModalPortal'
import { AxiosError } from 'axios'
import { ErrorMessage } from '../types/Error'
import SelfCheck from '../components/recruitment/modal/SelfCheck'
import colors from '../utils/colors'
import RequireText from '../components/elements/RequireText'
import Inquiry from '../components/recruitment/\bInquiry'

const Recruit = () => {
    const dispatch = useAppDispatch()

    const getItem = sessionStorage.getItem('recruit_no')
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState(0)
    const [timeOver, setTimeOver] = useState(false)
    const [stateText, setStateText] = useState('')
    const [selfCheckNo, setSelfCheckNo] = useState(0)
    const [isShowInquiry, setIsShowInquiry] = useState(false)
    const [recruitDetail, setRecruitDetail] = useState<RecruitDetail>({
        recruit_no: 0,
        start_date: '',
        end_date: '',
        recruit_name: '',
        visibility: false,
        employ_count: 0,
        image_url: '',
        first_start_date: '',
        first_end_date: '',
        apply_type: [],
        second_start_date: '',
        second_end_date: '',
        recruit_context: '',
        announce_date: '',
        bg_color: '',
    })

    const dateCheck = ({ start, end }: { start: any; end: any }) => {
        const today = dayjs().valueOf()
        const startDate = dayjs(start).valueOf()
        const endDate = dayjs(end).valueOf()

        if (startDate > today) {
            setTimeOver(true)
            setStateText('공고 시작 전')
            sessionStorage.setItem('end', 'true')
        }
        if (today >= startDate && today <= endDate) {
            setTimeOver(false)
            setStateText('공고 진행 중')
            sessionStorage.setItem('end', 'false')
        }
        if (today >= endDate) {
            setTimeOver(true)
            setStateText('공고 마감')
            sessionStorage.setItem('end', 'true')
        }
    }

    const handleRecruitDetailApi = async () => {
        try {
            const res = await get<RecruitDetail>(
                `${process.env.REACT_APP_PREFIX}/recruits/${params.id}`,
            )

            setRecruitDetail(res.data)
            dispatch(setTypes(res.data.apply_type.filter((v) => v.isVisible)))
            // 상세 정보를 저장헀으나 새로고침시 없어져 세션 스토리지에 담음
            sessionStorage.setItem('announce_date', res.data.announce_date)
            dispatch(setRecruitDetailInfo(res.data))
            dispatch(
                setGlobalBg({
                    bgColor: res.data.bg_color,
                    src: `http://${res.data.image_url}`,
                }),
            )
            sessionStorage.setItem('imgUrl', `http://${res.data.image_url}`)
            sessionStorage.setItem('bgColor', `${res.data.bg_color}`)
            dateCheck({
                start: res.data.start_date,
                end: res.data.end_date,
            })
            const diff = dayjs().diff(dayjs(res.data.first_start_date))
            setIsShowInquiry(diff > 0)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            alert((response?.data as ErrorMessage)?.detail)
        }
    }

    // 기존 모달의 번호를 자가 진단 번호로 넘기고 창을 바꿈
    const setShowSelfCheck = () => {
        setSelfCheckNo(modalType)
        setModalType(99)
    }

    const handleModal = useCallback((modalNo: number) => {
        setModalType(modalNo)
        setShowModal(true)
    }, [])

    const handleRoute = () => {
        navigate(`${location.pathname}/apply/1`)
    }

    const parseHtml = (param: string) => {
        return { __html: param }
    }

    useEffect(() => {
        handleRecruitDetailApi()
    }, [])

    useEffect(() => {
        if (!sessionStorage.getItem('recruit_no')) {
            sessionStorage.setItem('recruit_no', recruitDetail.recruit_no + '')
        }
        if (sessionStorage.getItem('recruit_no')) {
            sessionStorage.removeItem('access_token')
            sessionStorage.removeItem('token_type')
            sessionStorage.setItem('recruit_no', recruitDetail.recruit_no + '')
        }
    }, [getItem, recruitDetail.recruit_no])
    useEffect(() => {
        sessionStorage.removeItem('userInfo')
        sessionStorage.removeItem('currentPage')
        sessionStorage.removeItem('agree')
    }, [])
    return (
        <AppLayout
            bgColor={recruitDetail.bg_color}
            src={`http://${recruitDetail.image_url}`}
        >
            <Layout>
                {isShowInquiry && <Inquiry />}

                <h2 className="title">모집 자격</h2>
                <div className="help_user_type">
                    {recruitDetail.apply_type.map((item) => (
                        <TypeButton
                            key={item.apply_type_no}
                            type={'confirm'}
                            icon={true}
                            sort={false}
                            onClick={() => handleModal(item.apply_type_no)}
                            // disabled={!item.isVisible}
                        >
                            {item.apply_type_name}
                        </TypeButton>
                    ))}
                </div>
                <div>
                    <h2 className="title">업무 소개</h2>
                    <div className="recruit_info">
                        <div
                            className="context"
                            dangerouslySetInnerHTML={parseHtml(
                                recruitDetail.recruit_context,
                            )}
                        />
                    </div>
                </div>
                <div className="footer_contents">
                    <ReceiptPeriod
                        start={recruitDetail.start_date}
                        end={recruitDetail.end_date}
                        state={stateText}
                        typeColor={timeOver}
                    />
                    <TypeButton
                        type={'confirm'}
                        onClick={() => handleRoute()}
                        icon={false}
                        sort={true}
                        fSize={18}
                        disabled={timeOver}
                    >
                        지원하기
                    </TypeButton>
                </div>
            </Layout>

            {showModal && (
                <ModalPortal>
                    <Modal setShowModal={() => {}}>
                        {modalType === 1 && (
                            <YoungPeople
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 2 && (
                            <CareerInterrupted
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 3 && (
                            <NearPoverty
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 4 && (
                            <Handicapped
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 5 && (
                            <MultiCultural
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 6 && (
                            <Normal
                                setShowModal={setShowModal}
                                setShowSelfCheck={setShowSelfCheck}
                            />
                        )}
                        {modalType === 99 && (
                            <SelfCheck
                                applyTypeNo={selfCheckNo}
                                setShowModal={setShowModal}
                            />
                        )}
                    </Modal>
                </ModalPortal>
            )}
        </AppLayout>
    )
}

export default Recruit

const Layout = styled.div`
    margin: 84px 0 70px 0;
    .title {
        font-weight: 600;
        color: ${colors.textBoldColor};
        line-height: 21px;
        font-size: 18px;
    }
    .help_user_type {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 50px 0;
    }
    .recruit_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 50px 0;
        padding: 6px 0 12px 0;
        border-top: 1px solid ${colors.borderGray};
        border-bottom: 1px solid ${colors.borderGray};
        box-sizing: border-box;
        .context {
            img {
                width: 100%;
            }
        }
    }
    .footer_contents {
        display: flex;
        justify-content: space-between;
    }
`
