import styled from '@emotion/styled'
import React from 'react'
import TypeButton from '../../elements/TypeButton'
import ModalStyled from '../../recruitment/modal/ModalStyled'
import SelfAppraisal from '../../recruitment/SelfAppraisal'

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}
const ResultModal: React.FC<Props> = ({ setShowModal }) => {
    return (
        <ModalStyled>
            <SelfAppraisal subject={'제출 완료'} setShowModal={setShowModal}>
                <Content>
                    <div className="icon tit">
                        <DtIcon />
                    </div>
                    <div className="title">정보 제출 완료</div>
                    <div className="small">
                        정보 제출이 완료 돠었습니다.
                        <br />
                        정보 제출이 잘못되거나 다시 제출을 원한다면
                        <br /> 다시 작성 후 재제출하여 주시면 됩니다.
                    </div>
                </Content>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%',
                        bottom: 38,
                        left: 0,
                    }}
                >
                    <TypeButton
                        type={'cancel'}
                        onClick={() => {
                            setShowModal(false)
                        }}
                        icon={false}
                        sort={true}
                        size={100}
                        fSize={18}
                    >
                        닫기
                    </TypeButton>
                </div>
            </SelfAppraisal>
        </ModalStyled>
    )
}

export default ResultModal

const Content = styled.div`
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #333333;

    .icon {
        width: 100%;
        margin-bottom: 40px;
        &.tit {
            margin-bottom: 20px;
        }
    }

    .title {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        text-align: center;
        color: #333333;
    }
    .small {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #333333;
    }
    .interview {
        margin-top: 14px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-align: center;

        color: #f94733;
    }
`

const DtIcon = styled.i`
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url('/icon/SelfCheckDt.svg');
    background-size: 100% 100%;
`
