import styled from '@emotion/styled'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Input from '../elements/Input'
import InputGroup from '../elements/InputGroup'
import Modal from '../Modal'
import TypeButton from '../elements/TypeButton'
import { PatternFormat } from 'react-number-format'
import PrevWithNextArea from './PrevWithNextArea'
import useInputs from '../../hooks/useInputs'
import { post } from '../../api/customAxios'
import { AxiosError } from 'axios'
import RequireText from '../elements/RequireText'
import { Agree } from '../../types/Agree'
import DaumPostcodeEmbed from 'react-daum-postcode'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    firstNameLengthCheck,
    phoneCheck,
    textCheck,
} from '../../utils/validation'
import Indicator from '../Indicator'
import { ErrorType } from '../../types/Error'
import colors from '../../utils/colors'

interface Address {
    address: string
    detail?: string
}
interface BirthDate {
    firstNumber: string
    lastNumber: string
    user_email: string
}

interface Applicant {
    user_name: string
    user_email: string
    user_phone_number: string
    birth_date: string
    recruit_no: number
    user_address: string
    user_address_detail: string
}
interface Token {
    access_token: string
    token_type: string
    current_page: number
    applicant?: Applicant
}
interface ErrorMessage {
    detail: string
}
interface Props {
    onNext: () => void
    onPrev: () => void
    terms: Agree
}
const RequireUserInfo: React.FC<Props> = ({ onNext, onPrev, terms }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState(0)
    const [success, setSuccess] = useState(false)
    const [showMoveModal, setShowMoveModal] = useState(false)
    const [min, setMin] = useState(9)
    const [sec, setSec] = useState(59)
    const [authSuccess, setAuthSuccess] = useState(false)
    const [sendGuide, setSendGuide] = useState(false)
    const [warningMessage, setWarningMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // 정보 입력 잘못했을 경우의 에러메세지
    const [errorTypeMessage, setErrorTypeMessage] = useState<ErrorType>({
        type: '',
        message: '',
        status: false,
    })
    const [
        birthDateWithEmail,
        handleChangeBirthWithEmail,
        setBirthDateWithEmail,
    ] = useInputs({
        firstNumber: '',
        lastNumber: '',
        user_email: '',
    })
    const [formModel, handleChangeFormModel, setFormModel] = useInputs({
        recruit_no: 0,
        user_email: '',
        user_phone_number: '',
        birth_date: '',
        user_address: '',
        user_address_detail: '',
        user_name: '',
    })

    const {
        user_name,
        user_email,
        user_address,
        user_address_detail,
        user_phone_number,
        birth_date,
    } = formModel
    // 다음버튼에 대한 상태
    const [requireCheck, setRequireCheck] = useState(true)
    // 주소 검색 모달
    const [showModal, setShowModal] = useState(false)
    // 이메일 유효성 검사용
    const [showNoValidEmail, setShowNoValidEmail] = useState(false)
    // 인증번호 input
    const [emailConfirmNumber, setEamilConfirmNumber] = useState('')
    // 인증번호 창
    const [showEmailConfirmNumber, setShowEamilConfirmNumber] = useState(false)
    // 이메일 인증 관련 상태
    const [emailConfirmState, setEmailConfirmState] = useState(false)
    // 필수 값 입력에 대한 상태
    const [allRequire, setAllRequire] = useState(false)
    // delay
    const [delay, setDelay] = useState(false)
    const interval = useRef<NodeJS.Timer>()
    const disabledTimer = useRef<NodeJS.Timer>()
    const handleComplete = (data: Address) => {
        setShowModal(false)
        setFormModel((prev: Applicant) => {
            return { ...prev, user_address: data.address }
        })
    }

    const handleMove = () => {
        setTimeout(() => {
            const prefix = location.pathname.slice(0, -2)
            navigate(`${prefix}/${currentPage}`)
        }, 1000)
    }

    const regValidationCheck = useCallback(() => {
        if (firstNameLengthCheck(birthDateWithEmail.firstNumber)) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'birth',
                message:
                    '생년월일은 1월 ~ 12월, 1일 ~ 31일까지만 입력이 가능합니다.',
            }))
        } else if (textCheck(user_name)) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'name',
                message: '한글과 영문만 입력할 수 있습니다.',
            }))
        } else if (phoneCheck(user_phone_number)) {
            setErrorTypeMessage((prev) => ({
                ...prev,
                type: 'phone',
                message: '휴대전화번호 형식에 맞게 입력해주세요.',
                status: true,
            }))
        } else setErrorTypeMessage({ type: '', message: '', status: false })
    }, [birthDateWithEmail.firstNumber, user_name, user_phone_number])

    // 이메일 인증번호 발송 api
    const handleEmailConfirmApi = async () => {
        try {
            await post(`${process.env.REACT_APP_PREFIX}/emails/auth`, {
                email: birthDateWithEmail.user_email,
                recruit_no: sessionStorage.getItem('recruit_no'),
            })
            setSendGuide(true)
            setWarningMessage(false)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            if (response?.data) {
                alert((response.data as ErrorMessage).detail)
            }
            setSendGuide(false)
            // 잦은 요청 에러 경우 예외처리
            setWarningMessage(false)
            setShowNoValidEmail(false)
            setShowEamilConfirmNumber(false)
            setEmailConfirmState(false)
            setDelay(false)
        }
    }

    // 이메일 인증 api
    const handleAuthEmailConfirmApi = async () => {
        try {
            setIsLoading(true)
            const res = await post<Token>(
                `${process.env.REACT_APP_PREFIX}/emails/verify`,
                {
                    email: birthDateWithEmail.user_email,
                    auth_number: emailConfirmNumber,
                    recruit_no: sessionStorage.getItem('recruit_no'),
                },
            )
            if (res.data.access_token) {
                sessionStorage.setItem('access_token', res.data.access_token)
                sessionStorage.setItem('token_type', res.data.token_type)
            }

            if (res.data.current_page > 0) {
                const firstNumber = res.data.applicant?.birth_date.split('-')[0]
                const lastNumber = res.data.applicant?.birth_date.split('-')[1]

                setBirthDateWithEmail((prev: BirthDate) => ({
                    ...prev,
                    firstNumber,
                    lastNumber,
                }))
                setFormModel((prev: Applicant) => {
                    return {
                        ...prev,
                        user_email: res.data.applicant?.user_email,
                        user_phone_number:
                            res.data.applicant?.user_phone_number,
                        birth_date:
                            birthDateWithEmail.firstNumber +
                            '-' +
                            birthDateWithEmail.lastNumber,
                        user_address: res.data.applicant?.user_address,
                        user_address_detail:
                            res.data.applicant?.user_address_detail,
                        user_name: res.data.applicant?.user_name,
                    }
                })
                setCurrentPage(res.data.current_page)
                sessionStorage.setItem(
                    'currentPage',
                    res.data.current_page + '',
                )
                if (sessionStorage.getItem('userInfo'))
                    sessionStorage.removeItem('userInfo')
                sessionStorage.setItem(
                    'userInfo',
                    JSON.stringify(res.data?.applicant),
                )
            }
            setAuthSuccess(true)
            setWarningMessage(false)
            setSendGuide(false)
        } catch (e) {
            const { response } = e as unknown as AxiosError
            // alert((response?.data as ErrorMessage)?.detail)
            console.log('error', response)
            setSendGuide(false)
            setWarningMessage(true)
        } finally {
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
        }
    }

    // 이메일 검증 관련
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const handleEmailConfirm = () => {
        if (
            birthDateWithEmail.user_email &&
            birthDateWithEmail.user_email.match(isValidEmail)
        ) {
            handleEmailConfirmApi()
            setShowNoValidEmail(false)
            setShowEamilConfirmNumber(true)
            setEmailConfirmState(true)
            setDelay(true)
        } else {
            setShowNoValidEmail(true)
        }
    }

    // 인증번호 컨펌
    const verificationCodeConfirm = () => {
        handleAuthEmailConfirmApi()
        setFormModel((prev: Applicant) => {
            return {
                ...prev,
                user_email: birthDateWithEmail.user_email,
                birth_date:
                    birthDateWithEmail.firstNumber +
                    '-' +
                    birthDateWithEmail.lastNumber,
            }
        })
    }

    // 정보가 입력됐는지에 대한 유효성 체크
    const validationCheck = useCallback(() => {
        // TODO
        if (
            user_name !== '' &&
            user_email !== '' &&
            user_address !== '' &&
            user_phone_number !== '' &&
            birth_date !== '' &&
            user_phone_number.trim().length > 12 &&
            user_name.trim().length > 1 &&
            errorTypeMessage.type === '' &&
            birthDateWithEmail.firstNumber.length === 6 &&
            birthDateWithEmail.lastNumber.length === 1
        ) {
            return true
        } else {
            return false
        }
    }, [
        birth_date,
        user_address,
        user_address_detail,
        user_email,
        user_name,
        user_phone_number,
        errorTypeMessage.type,
        birthDateWithEmail.firstNumber,
        birthDateWithEmail.lastNumber,
    ])

    // 모든 값이 입력되었을 때 다음 버튼 활성화
    const handleAllSuccess = async () => {
        if (validationCheck()) {
            // 서버에 api 투척
            try {
                const res = await post<Token>(
                    `${process.env.REACT_APP_PREFIX}/applies`,
                    {
                        param: {
                            recruit_no: sessionStorage.getItem('recruit_no'),
                            birth_date:
                                birthDateWithEmail.firstNumber +
                                '-' +
                                birthDateWithEmail.lastNumber,
                            user_address,
                            user_address_detail,
                            user_email,
                            user_name,
                            user_phone_number,
                        },
                        terms,
                    },
                )
                if (res.data?.access_token) {
                    sessionStorage.setItem(
                        'access_token',
                        res.data.access_token,
                    )
                    sessionStorage.setItem('token_type', res.data.token_type)
                }

                sessionStorage.setItem('userInfo', JSON.stringify(formModel))
                onNext()
            } catch (e) {
                const { response } = e as unknown as AxiosError
                // alert((response?.data as ErrorMessage)?.detail)
            }
        }
    }

    // 밸리데이션 체크를 하는 useEffect
    useEffect(() => {
        regValidationCheck()
    }, [regValidationCheck])

    useEffect(() => {
        if (authSuccess) {
            setRequireCheck(false)
            setSuccess(true)
        }
    }, [authSuccess])

    // current page가 존재할 경우 존재하는 페이지로 이동할지에 대한 useEffect
    useEffect(() => {
        if (currentPage > 2) {
            // alert('작성중이던 페이지가 있습니다 이동하시겠습니까?')
            setShowMoveModal(true)
        }
    }, [currentPage])

    useEffect(() => {
        setFormModel((prev: Applicant) => {
            return {
                ...prev,
                user_name,
                user_email,
                user_address,
                user_address_detail,
                user_phone_number,
                birth_date:
                    birthDateWithEmail.firstNumber +
                    '-' +
                    birthDateWithEmail.lastNumber,
            }
        })
    }, [birthDateWithEmail])

    useEffect(() => {
        if (showNoValidEmail) {
            setTimeout(() => {
                setShowNoValidEmail(false)
            }, 1000)
        }
    }, [showNoValidEmail])

    // 유효성 체크 후 button 활성화
    useEffect(() => {
        if (validationCheck()) {
            setAllRequire(true)
        } else {
            setAllRequire(false)
        }
    }, [validationCheck])
    // 인증번호 타이머
    useEffect(() => {
        if (emailConfirmState)
            disabledTimer.current = setTimeout(() => {
                setDelay(false)
                if (!delay) clearTimeout(disabledTimer.current)
            }, 60000)

        return () => {
            if (!delay) clearTimeout(disabledTimer.current)
        }
    }, [delay])

    // 인증번호 타이머
    useEffect(() => {
        if (delay) {
            setMin(9)
            setSec(59)
            interval.current = setInterval(() => {
                setSec((prev) => prev - 1)
            }, 1000)
        }
        return () => {
            if (!delay) clearInterval(interval.current)
        }
    }, [delay])

    // 인증번호 타이머
    useEffect(() => {
        if (sec === 0) {
            setMin((prev) => prev - 1)
        }
        if (sec < 0) {
            setSec(59)
        }
        if (min === 0 && sec === 0) {
            setMin(0)
            setSec(0)
            clearInterval(interval.current)
            setEmailConfirmState(false)
        }
    }, [sec])
    useEffect(() => {
        if (!emailConfirmState) {
            clearInterval(interval.current)
            setMin(0)
            setSec(0)
        }
    }, [emailConfirmState, delay])

    // success 가 true가 될 경우 타이머를 종료
    useEffect(() => {
        if (success) clearInterval(interval.current)
    }, [success])
    useEffect(() => {
        if (authSuccess) clearInterval(interval.current)
    }, [authSuccess])

    useEffect(() => {
        if (sessionStorage.getItem('userInfo')) {
            setFormModel((prev: Applicant) => ({
                ...prev,
                ...JSON.parse(sessionStorage.getItem('userInfo') || '{}'),
            }))
            setBirthDateWithEmail((prev: BirthDate) => ({
                ...prev,
                user_email: JSON.parse(
                    sessionStorage.getItem('userInfo') || '{}',
                ).user_email,
                firstNumber: JSON.parse(
                    sessionStorage.getItem('userInfo') || '{}',
                ).birth_date.split('-')[0],
                lastNumber: JSON.parse(
                    sessionStorage.getItem('userInfo') || '{}',
                ).birth_date.split('-')[1],
            }))
            setAuthSuccess(true)
        }
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <Layout>
                <form onSubmit={(e) => e.preventDefault()}>
                    <RequireBox>
                        <RequireText title="* 표시가 된 칸은 필수로 입력해야 합니다." />
                    </RequireBox>

                    <InputGroup
                        title="이메일"
                        require={true}
                        margin={
                            showEmailConfirmNumber ? '0 0 10px 0' : '0 0 22px 0'
                        }
                    >
                        <Input
                            onChange={handleChangeBirthWithEmail}
                            // min={2}
                            // max={20}
                            width={250}
                            type={'text'}
                            name={'user_email'}
                            value={birthDateWithEmail.user_email}
                            disabled={emailConfirmState || authSuccess}
                            background={
                                authSuccess
                                    ? colors.confirmDisabledColor
                                    : colors.disabledColor
                            }
                            line={
                                showNoValidEmail
                                    ? `2px solid ${colors.warningColor}`
                                    : ''
                            }
                            disabledBorder={authSuccess ? '0' : ''}
                            placeholder="ex) sample@gmail.com"
                            required
                        />
                        {/* 인증 시간 1분 지났을 경우 disabled 풀어줘야함 */}
                        <TypeButton
                            size={100}
                            icon={false}
                            sort={false}
                            disabled={delay || authSuccess}
                            onClick={handleEmailConfirm}
                            type={'confirm'}
                            margin={'0 0 0 18px'}
                        >
                            인증
                        </TypeButton>
                    </InputGroup>

                    {showEmailConfirmNumber && (
                        <>
                            <InputGroup title="">
                                <EmailConfirmBox>
                                    <Input
                                        onChange={(e) =>
                                            setEamilConfirmNumber(
                                                e.target.value,
                                            )
                                        }
                                        width={250}
                                        max={4}
                                        type={'text'}
                                        name={emailConfirmNumber}
                                        value={emailConfirmNumber}
                                        placeholder="ex) 1234"
                                        line={
                                            warningMessage
                                                ? `2px solid ${colors.warningColor}`
                                                : ''
                                        }
                                        background={
                                            authSuccess
                                                ? 'linear-gradient(180deg, #FEFEFE 0%, #FCFCFC 100%)'
                                                : colors.disabledColor
                                        }
                                        disabledBorder={
                                            authSuccess
                                                ? `2px solid ${colors.confirmGreen}`
                                                : ''
                                        }
                                        disabled={authSuccess} // 후에 background 처리해줘야함.
                                    />
                                    {warningMessage && <WarningIcon />}
                                    {authSuccess && <ConfirmIcon />}
                                </EmailConfirmBox>
                                <TypeButton
                                    size={70}
                                    icon={false}
                                    sort={false}
                                    onClick={() => verificationCodeConfirm()}
                                    type={'confirm'}
                                    disabled={
                                        !(emailConfirmNumber.length === 4) ||
                                        authSuccess
                                    }
                                    margin={'0 0 0 18px'}
                                >
                                    확인
                                </TypeButton>
                                <TimerBox>
                                    {min > 9 ? min : '0' + min}:
                                    {sec > 9 ? sec : '0' + sec}
                                    남음
                                </TimerBox>
                            </InputGroup>
                            {sendGuide && (
                                <SendGuideMessage>
                                    인증번호가 이메일로 발송 되었습니다.
                                    이메일을 확인해서 인증번호를 입력해주세요.
                                    <br />
                                    인증메일이 스팸으로 분류 될 수 있으니
                                    스팸메일함 혹은 휴지통을 확인해보세요.
                                </SendGuideMessage>
                            )}
                            {warningMessage && (
                                <ErrorBox>
                                    <p
                                        style={{
                                            position: 'relative',
                                            top: -14,
                                            left: 110,
                                        }}
                                        className="warning_message"
                                    >
                                        인증번호가 틀렸습니다.
                                    </p>
                                </ErrorBox>
                            )}
                        </>
                    )}

                    {showNoValidEmail && (
                        <ErrorBox
                            style={{
                                position: 'relative',
                                height: 30,
                            }}
                        >
                            <p
                                className="warning_message"
                                style={{
                                    position: 'absolute',
                                    left: 110,
                                }}
                            >
                                올바른 이메일 형식이 아닙니다.
                            </p>
                        </ErrorBox>
                    )}
                    <InputGroup title={'주민등록번호'} require={true}>
                        <div>
                            <Input
                                onChange={(e) => {
                                    if (!isNaN(+e.target.value)) {
                                        setBirthDateWithEmail(
                                            (prev: BirthDate) => {
                                                return {
                                                    ...prev,
                                                    firstNumber: e.target.value,
                                                }
                                            },
                                        )
                                    }
                                }}
                                max={6}
                                type={'text'}
                                name={'firstNumber'}
                                value={birthDateWithEmail.firstNumber}
                                placeholder="ex) 901230"
                                disabledBorder={authSuccess ? '0' : '#ccc'}
                                disabled={requireCheck}
                                line={
                                    errorTypeMessage.type === 'birth'
                                        ? `2px solid ${colors.warningColor}`
                                        : `2px solid ${colors.borderGray}`
                                }
                                required
                            />
                            {errorTypeMessage.type === 'birth' && (
                                <ErrorText>
                                    {errorTypeMessage.message}
                                </ErrorText>
                            )}
                        </div>

                        <MarginBox>-</MarginBox>
                        <Input
                            onChange={(e) => {
                                if (!isNaN(+e.target.value)) {
                                    setBirthDateWithEmail((prev: BirthDate) => {
                                        return {
                                            ...prev,
                                            lastNumber: e.target.value,
                                        }
                                    })
                                }
                            }}
                            max={1}
                            width={34}
                            type={'text'}
                            name={'lastNumber'}
                            value={birthDateWithEmail.lastNumber}
                            disabledBorder={authSuccess ? '0' : '#ccc'}
                            disabled={requireCheck}
                            required
                        />
                    </InputGroup>
                    <InputGroup title="이름" require={true}>
                        <div>
                            <Input
                                onChange={handleChangeFormModel}
                                width={250}
                                max={20}
                                min={2}
                                type={'text'}
                                name={'user_name'}
                                value={formModel.user_name}
                                placeholder="ex) 홍길동"
                                disabledBorder={authSuccess ? '0' : '#ccc'}
                                disabled={requireCheck}
                                line={
                                    errorTypeMessage.type === 'name'
                                        ? `2px solid ${colors.warningColor}`
                                        : `2px solid ${colors.borderGray}`
                                }
                                required
                            />
                            {errorTypeMessage.type === 'name' && (
                                <ErrorText>
                                    {errorTypeMessage.message}
                                </ErrorText>
                            )}
                        </div>
                    </InputGroup>
                    <InputGroup
                        title="주소"
                        require={true}
                        margin={'0 0 10px 0'}
                    >
                        <Input
                            onChange={handleChangeFormModel}
                            width={368}
                            type={'text'}
                            name={'user_address'}
                            value={formModel.user_address}
                            disabled={true}
                            background={
                                success
                                    ? colors.confirmDisabledColor
                                    : colors.disabledColor
                            }
                            disabledBorder={'0'}
                            placeholder="ex) 서울특별시 강남구 역삼동 123-4"
                            required
                        />
                        <TypeButton
                            size={100}
                            icon={false}
                            sort={false}
                            onClick={() => setShowModal(true)}
                            type={'confirm'}
                            margin={'0 0 0 18px'}
                            disabled={requireCheck}
                        >
                            검색
                        </TypeButton>
                    </InputGroup>
                    <InputGroup title="">
                        <Input
                            onChange={handleChangeFormModel}
                            width={368}
                            type={'text'}
                            name={'user_address_detail'}
                            value={formModel.user_address_detail}
                            disabled={requireCheck}
                            disabledBorder={authSuccess ? '0' : '#ccc'}
                            placeholder={'상세주소를 입력해주세요'}
                        />
                    </InputGroup>
                    <InputGroup title="휴대전화" require={true}>
                        <div>
                            <PatternInput
                                width={368}
                                format={'###-####-####'}
                                placeholder={'ex) 010-1234-5678'}
                                value={formModel.user_phone_number}
                                name={'user_phone_number'}
                                onChange={handleChangeFormModel}
                                disabled={requireCheck}
                                autoComplete="off"
                                border={
                                    errorTypeMessage.type === 'phone'
                                        ? colors.warningColor
                                        : colors.borderGray
                                }
                                required
                            />
                            {errorTypeMessage.type === 'phone' && (
                                <ErrorText>
                                    {errorTypeMessage.message}
                                </ErrorText>
                            )}
                        </div>
                    </InputGroup>
                </form>

                {isLoading && <Indicator isLoading={isLoading} top={70} />}

                <PrevWithNextArea
                    onNext={handleAllSuccess}
                    onPrev={onPrev}
                    success={allRequire}
                />
            </Layout>

            {showModal && (
                <Modal setShowModal={setShowModal}>
                    <ModalClose>
                        <div className="close">
                            <span onClick={() => setShowModal(false)}>
                                닫기
                            </span>
                        </div>
                        <DaumPostcodeEmbed
                            className="post_address"
                            onComplete={handleComplete}
                        />
                    </ModalClose>
                </Modal>
            )}
            {showMoveModal && (
                <Modal setShowModal={setShowMoveModal}>
                    <ModalLayout>
                        <ModalContents>
                            작성하던 내용이 있습니다.
                            <br />
                            이어서 작성합니다.
                        </ModalContents>
                        <div style={{ marginBottom: 36 }}>
                            <TypeButton
                                onClick={handleMove}
                                size={100}
                                icon={false}
                                sort={false}
                                type={'confirm'}
                            >
                                닫기
                            </TypeButton>
                        </div>
                    </ModalLayout>
                </Modal>
            )}
        </div>
    )
}

export default RequireUserInfo

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    form {
        margin-bottom: 80px;
    }

    .require {
        font-size: 16px;
        font-weight: 700;
        color: ${colors.warningSubColor};
    }
`

const PatternInput = styled(PatternFormat)<{ border?: string }>`
    width: ${({ width }) => (width ? width : 130)}px;
    height: ${({ height }) => (height ? height : 40)}px;

    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
    border: 2px solid ${({ border }) => border && border};
    border-radius: 4px;
    padding: 0 10px;
    font-size: 14px;

    outline: none;
    box-sizing: border-box;

    &::placeholder {
        color: ${colors.borderGray};
    }
    &:disabled {
        background: ${colors.disabledColor};
        border: 0;
        cursor: not-allowed;
    }
`

const MarginBox = styled.div`
    margin: 0 16px;
`

const TimerBox = styled.div`
    margin-left: 12px;
    font-size: 12px;
    font-weight: 600;
`

const ErrorBox = styled.div`
    positoin: relative;
    display: flex;
    margin: 0 0 0 22px;
    .warning_message {
        color: ${colors.warningColor};
        font-size: 12px;
        line-height: 14px;
    }
`

const ErrorText = styled.p`
    position: absolute;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: ${colors.warningColor};
`

const WarningIcon = styled.i`
    position: absolute;
    right: 10px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/icon/WarningIcon.svg');
`

const ConfirmIcon = styled.i`
    position: absolute;
    right: 10px;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('/icon/ConfirmIcon.svg');
`
const RequireBox = styled.div`
    margin-bottom: 20px;
`

const EmailConfirmBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const SendGuideMessage = styled.div`
    position: relative;
    left: 120px;
    top: -10px;
    color: #2278f1;
    font-size: 14px;
    line-height: 14px;
`

const ModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 490px;
    height: 280px;
    margin: 0 auto;
    border-radius: 10px;
    background: linear-gradient(180deg, #fefefe 0%, #fcfcfc 100%);
`
const ModalContents = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
`

const ModalClose = styled.div`
    width: 400px;
    margin: 0 auto;
    background: white;
    .close {
        margin: 10px;
        text-align: right;
        span {
            cursor: pointer;
            font-weight: bold;
        }
    }
`
